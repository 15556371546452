import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";

// material-ui icons
import Add from "@material-ui/icons/Add";
import { withStyles } from '@material-ui/core/styles';

// material-ui components
import Tooltip from "@material-ui/core/Tooltip";

// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import Button from "../../../components/CustomButtons/Button.js";
import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";
import ReactTable from "../../../components/ReactTable/ReactTable.js";

// custom components
import WizardAddDevice from "./WizardAddDevice.js";
import WizardAddDeviceVision from "./WizardAddDeviceVision.js";
import WizardSubscription from "./WizardSubscription.js";
import WizardUpdateDevice from "./WizardUpdateDevice.js";
import WizardUpdateDeviceVision from "./WizardUpdateDeviceVision.js";

import ComputerVisionLog from "../ComputerVisionLog/ComputerVisionLog.js";

// tell webpack this js file uses the image
import PayPal from "../../../assets/img/paypal.png";

import regularFormStyles from "../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle.js";
import alertStyles from "../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import Loader from 'react-loader-spinner';
const loaderStyle = { position: "fixed", top: "50%", left: "55%", transform: "translate(-50%, -50%)"}
const loader = (<div style={loaderStyle}> <Loader type="ThreeDots" color="#4cb5f5" width="200" height="200" /></div>);

const payPalButtonStyle = { height: "35px", width: "82px", padding: "0px 0px 0px 0px", borderStyle: "none", borderRadius: "10px" }
const payPalSmallButtonStyle = { height: "30px", width: "62px", padding: "0px 0px 0px 0px", borderStyle: "none", borderRadius: "10px" }

const paypal_image_icon = new Image().src = "https://carexactsosprod.blob.core.windows.net/public/payPalSubscription.png";
const free_subscription_icon = new Image().src = "https://carexactsosprod.blob.core.windows.net/public/freeSubscription.png";

const style = {
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center"
    },
    inputAdornmentIcon: {
        color: "#555"
    },
    choiche: {
        textAlign: "center",
        cursor: "pointer",
        marginTop: "20px"
    },
    ...regularFormStyles,
    ...alertStyles
};

class Devices extends Component {
    interval = null;
   
    constructor(props) {
        super(props);

        this.state = {
            devicesData: null,
            selectedDevice: null,
            showAddDeviceWizardForm: false,
            showAddDeviceVisionWizardForm: false,
            showUpdateDeviceWizardForm: false,
            showSubscriptionWizardForm: false,
            showComputerVisionLog: false,
            mobilePortaitMode: false,
            alert: null
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.checkOrientation);
        window.addEventListener('orientationchange', this.checkOrientation);
        this.checkOrientation();
        this.getDeviceData();

        const searchParams = new URLSearchParams(window.location.search);
        const params = { productCode: searchParams.get('productCode') || '', action: searchParams.get('action') || '', };

        if (params.productCode !== '') {
            if (params.action === 'add') {
                localStorage.setItem("careXactSOS_ProductCode", params.productCode);

                (async () => {
                    var deviceType = await this.getDeviceType(params.productCode);

                    if (deviceType.toLowerCase() === "ev04" || deviceType.toLowerCase() === "ev05")
                        this.setState({ showAddDeviceWizardForm: true });

                    if (deviceType.toLowerCase() === "vision")
                        this.setState({ showAddDeviceVisionWizardForm: true });
                })();
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkOrientation);
        window.removeEventListener('orientationchange', this.checkOrientation);
        this.setState({ showForm: false });
    }

    checkOrientation = () => {
        if (window.innerHeight > window.innerWidth) {
            this.setState({ mobilePortaitMode: true });
        } else {
            this.setState({ mobilePortaitMode: false });
        }

        this.forceUpdate();
    }

    async getDeviceType(productCode) {
        const bearerToken = localStorage.getItem("careXactSOS_BearerToken");
        const apiURL = localStorage.getItem("careXactSOS_ApiURL");
        const authHeader = "Bearer " + bearerToken;
        const subscriptionKey = localStorage.getItem("careXactSOS_SubscriptionKey");
        const headers = { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': subscriptionKey, 'Authorization': authHeader };

        const response = await fetch(apiURL + 'api/Device/DeviceType?productCode=' + productCode, { headers })
        return await response.json();
    }   

    getDeviceData = () => {
        const bearerToken = localStorage.getItem("careXactSOS_BearerToken");

        if (bearerToken !== undefined) {
            const apiURL = localStorage.getItem("careXactSOS_ApiURL");
            const authHeader = "Bearer " + bearerToken;
            const subscriptionKey = localStorage.getItem("careXactSOS_SubscriptionKey");
            const headers = { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': subscriptionKey, 'Authorization': authHeader };

            fetch(apiURL + 'api/Device/AllDevices', { headers })
                .then(response => response.json())
                .then(data => { this.setState({ devicesData: data }); })
                .catch(error => {

                });
        }
    }

    showUpdateDeviceWizardForm = () => {
        localStorage.setItem("careXactSOS_Device", JSON.stringify(this.state.selectedDevice));

        if (this.state.selectedDevice.DeviceType.toLowerCase() === "ev04" || this.state.selectedDevice.DeviceType.toLowerCase() === "ev05") {
            return (
                <WizardUpdateDevice closeForm={this.closeChildForm} closeFormNoRefresh={this.closeFormNoRefresh} />
            );
        } else if (this.state.selectedDevice.DeviceType.toLowerCase() === "vision") {
            return (
                <WizardUpdateDeviceVision closeForm={this.closeChildForm} closeFormNoRefresh={this.closeFormNoRefresh} />
            );
        }
    }

    showAddDeviceWizardForm = () => {
        localStorage.removeItem("careXactSOS_Device");
        return (
            <WizardAddDevice closeForm={this.closeChildForm} closeFormNoRefresh={this.closeFormNoRefresh} closeFormLaunchPayment={this.closeFormLaunchPayment} />
        );
    }

    showAddDeviceVisionWizardForm = () => {
        localStorage.removeItem("careXactSOS_Device");
        return (
            <WizardAddDeviceVision closeForm={this.closeChildForm} closeFormNoRefresh={this.closeFormNoRefresh} closeFormLaunchPayment={this.closeFormLaunchPayment} />
        );
    }

    showComputerVisionLog = () => {
        return (
            <ComputerVisionLog closeForm={this.closeFormNoRefresh} productCode={this.state.selectedDevice.ProductCode} />
        );
    }

    showSubscriptionWizardForm = () => {
        return (
            <WizardSubscription closeForm={this.closeChildForm} closeFormNoRefresh={this.closeFormNoRefresh} />
        );
    }

    closeChildForm = (action) => {

        // show popup user instructions for eView devices
        if (this.state.selectedDevice !== null) {
            if (this.state.selectedDevice.DeviceType.toLowerCase() === "ev04" || this.state.selectedDevice.DeviceType.toLowerCase() === "ev05") {
                if (action === "ADD") this.showAddWarningAlert();
                if (action === "ADD_WITH_SUBSCRIPTION") this.showAddWithSubscribedSuccessAlert();
                if (action === "UPDATE") this.showUpdateSuccessAlert();
            }
        }

        localStorage.removeItem("careXactSOS_Device");
        localStorage.removeItem("careXactSOS_ProductCode");
        this.getDeviceData();
        this.setState({ showUpdateDeviceWizardForm: false, showAddDeviceWizardForm: false, showAddDeviceVisionWizardForm: false, showComputerVisionLog: false, showSubscriptionWizardForm: false });
        window.scrollTo(0, 0);
    }

    closeFormNoRefresh = () => {
        localStorage.removeItem("careXactSOS_Device");
        localStorage.removeItem("careXactSOS_ProductCode");
        this.setState({ showUpdateDeviceWizardForm: false, showAddDeviceWizardForm: false, showAddDeviceVisionWizardForm: false, showComputerVisionLog: false, showSubscriptionWizardForm: false });
        window.scrollTo(0, 0);
    }

    closeFormLaunchPayment = () => {
        this.getDeviceData();
        this.setState({ showUpdateDeviceWizardForm: false, showAddDeviceWizardForm: false, showAddDeviceVisionWizardForm: false, showSubscriptionWizardForm: true });
        window.scrollTo(0, 0);
    }

    countEnabledPhoneNumbers = (authorizedNumbers) => {

        if (authorizedNumbers === null) return 0;
        if (authorizedNumbers === undefined) return 0;

        var i = 0;
        var enabledPhoneNumberCount = 0;
        for (i = 0; i < authorizedNumbers.length; i++) {
            var authorizedNumber = authorizedNumbers[i];
            if (authorizedNumber.AuthorizedNumber.length > 0) {
                if (authorizedNumber.AcceptPhoneCall === "Y" || authorizedNumber.AcceptSMS === "Y")
                    enabledPhoneNumberCount++;
            }  
        }

        return enabledPhoneNumberCount;
    }

    launchPayment = (device) => {
        localStorage.setItem("careXactSOS_DeviceId", device.DeviceId);
        this.setState({ showSubscriptionWizardForm: true})
    }

    hideAlert = () => {
        this.setState({ alert: null });
    }

    showAddWarningAlert() {
        const { classes } = this.props;

        this.setState({
            alert:
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px", width: "500px" }}
                    onConfirm={this.hideAlert}
                    confirmBtnCssClass={
                        classes.button + " " + classes.success
                    }>
                    <div>
                        Your device will not be activated until a PayPal subscription is attached to the device.
                    </div>
                    <br />
                    <div>
                        When you are ready to activate your device press the PayPal button next to the device under your account.
                    </div>
                    <br />
                    <div>
                        Once the PayPal subscription is activated your device will be automatically activated within 10 minutes.
                    </div>
                </SweetAlert>
        });
    }

    showAddWithSubscribedSuccessAlert() {
        const { classes } = this.props;

        this.setState({
            alert:
                <SweetAlert
                    success
                    style={{ display: "block", marginTop: "-100px", width: "500px" }}
                    onConfirm={this.hideAlert}
                    confirmBtnCssClass={
                        classes.button + " " + classes.success
                    }>
                    <div>
                        The device and PayPal subscription are now active.
                    </div>
                    <br />
                    <div>
                        Please ensure the device is turned on so it can be automatically configured.
                    </div>
                    <br />
                    <div>
                        After 4 hours try a test SOS button press and ensure your emergency contacts receive a phone call or SMS.
                    </div>
                </SweetAlert>
        });
    }

    showUpdateSuccessAlert() {
        const { classes } = this.props;

        this.setState({
            alert:
                <SweetAlert
                    success
                    style={{ display: "block", marginTop: "-100px", width: "500px" }}
                    onConfirm={this.hideAlert}
                    confirmBtnCssClass={
                        classes.button + " " + classes.success
                    }>
                    <div>
                        Your device will be automatically updated in the next 4 hours.
                    </div>
                    <br />
                    <div>
                        Please ensure the device is turned on.
                    </div>
                    <br />
                    <div>
                        Emergency Contacts will receive an SMS confirmation once the device update is complete.
                    </div>
                </SweetAlert>
        });
    }

    showSubscription(classes, subscriptionTypePlanId, status, id) {
        var displayStatus = status === 1 ? "Active" : "Paused";

        this.setState({
            alert:
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px", width: "300px" }}
                    title=""
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    confirmBtnCssClass={
                        classes.button + " " + classes.success
                    }>
                    {subscriptionTypePlanId.toLowerCase() === "free" ? 
                        <div>
                            <img src={free_subscription_icon} height="100%" width="100%" objectFit="contain" />
                            <div align="left">
                                Status : {displayStatus}
                            </div>
                        </div>
                        :
                        <div>
                            <img src={paypal_image_icon} height="100%" width="100%" objectFit="contain" />
                            <div align="left">
                                Status : {displayStatus}
                            </div>
                            <div align="left">
                                Subscription Id : {id}
                            </div>
                        </div>
                    }
                </SweetAlert>
        });
    }

    getDeviceBatteryLevelValue(deviceType, batteryLevel) {

        if (deviceType === null || deviceType === undefined) return "";
        if (deviceType.toLowerCase() === "vision") return "";

        return batteryLevel;
    }

    getDeviceFallDetectionValue(deviceType, fallDetection) {

        if (deviceType === null || deviceType === undefined) return "";
        if (deviceType.toLowerCase() === "vision") return "";

        if (deviceType.toLowerCase() === "ev04" || deviceType.toLowerCase() === "ev05") {
            if (fallDetection === null) return "Disabled";
            if (fallDetection === undefined) return "Disabled";
            if (fallDetection[0].Enabled === "Y") return "Enabled";
            if (fallDetection[0].Enabled === "N") return "Disabled";
        }

        return fallDetection;
    }

    render() {
        const { classes } = this.props;

        var devicesData = this.state.devicesData !== null ? this.state.devicesData : null;
        if (devicesData === null) { return (loader); }
        if (devicesData === undefined) { return (loader); }

        let emptyObjArray = [];
        var emptyObj = {
            DeviceName: null,
            DeviceUser: null,
            FallEnabled: null,
            AuthorizedNumbers: null,
            BatteryLevel: null,
            SubscriptionId: null,
            Actions: null
        };
        emptyObjArray.push(emptyObj);

        if (this.state.showAddDeviceWizardForm) {
            window.scrollTo(0, 0);
            return this.showAddDeviceWizardForm();
        } else if (this.state.showAddDeviceVisionWizardForm) {
            window.scrollTo(0, 0);
            return this.showAddDeviceVisionWizardForm();
        } else if (this.state.showSubscriptionWizardForm) {
            window.scrollTo(0, 0);
            return this.showSubscriptionWizardForm();
        } else if (this.state.showUpdateDeviceWizardForm) {
            window.scrollTo(0, 0);
            return this.showUpdateDeviceWizardForm();
        } else if (this.state.showComputerVisionLog) {
            window.scrollTo(0, 0);
            return this.showComputerVisionLog();
        } 
        else {
            return (
                devicesData.Devices !== undefined ?
                    this.state.mobilePortaitMode === true ?
                        <GridContainer xs={12}>
                            {this.state.alert}
                            <GridItem xs={12}>
                                <Card>
                                    <CardBody>
                                        <Button
                                            color="transparent"
                                            round
                                            aria-label="Add Device"
                                            color="blueSky"
                                            onClick={() => this.setState({ showAddDeviceWizardForm: true, selectedDevice: null })}>
                                            <Add onClick={() => this.setState({ showAddDeviceWizardForm: true, selectedDevice: null })} /> Add Device
                                        </Button>
                                        {/*<Button
                                            color="transparent"
                                            round
                                            aria-label="Add Vision Device"
                                            color="blueSky"
                                            onClick={() => this.setState({ showAddDeviceVisionWizardForm: true, selectedDevice: null })}>
                                            <Add onClick={() => this.setState({ showAddDeviceVisionWizardForm: true, selectedDevice: null })} /> Add Vision Device
                                        </Button>*/}
                                        <hr />
                                        <ReactTable
                                            align="center"
                                            columns={[
                                                {
                                                    Header: "Name",
                                                    accessor: "DeviceName",
                                                    headerFontSize: 'smaller',
                                                    disableFilters: false,
                                                    minWidth: 80
                                                },
                                                {
                                                    Header: "Battery",
                                                    accessor: "BatteryLevel",
                                                    headerFontSize: 'smaller',
                                                    disableFilters: false,
                                                    minWidth: 60
                                                },
                                                {
                                                    Header: "",
                                                    accessor: "Actions",
                                                    headerFontSize: 'smaller',
                                                    disableFilters: true,
                                                    minWidth: 80
                                                }
                                            ]}
                                            data={devicesData.Devices.map((prop, key) => {
                                                return {
                                                    id: key,
                                                    DeviceName: prop["DeviceName"],
                                                    BatteryLevel: prop.DeviceLocation !== null ? this.getDeviceBatteryLevelValue(prop.DeviceType, prop.DeviceLocation.BatteryLevel + "%") : "",
                                                    Actions: (
                                                        <div className="actions-left">
                                                            {prop["PayPalSubscriptionId"] !== null ? <Button size="sm" color="blueSky" onClick={() => this.showSubscription(classes, prop["PayPalSubscriptionActive"], prop["PayPalSubscriptionId"])}>{prop["PayPalSubscriptionActive"] === null ? "PAUSED" : prop["PayPalSubscriptionActive"] === 1 ? "ACTIVE" : "PAUSED"}</Button> : <button style={payPalSmallButtonStyle} onClick={() => this.launchPayment(prop)}><img src={PayPal} height="30px" width="60px" onClick={() => this.launchPayment(prop)} /></button>}
                                                            <Tooltip title="Update Device"><Button disabled={prop["PayPalSubscriptionActive"] === null ? true : prop["PayPalSubscriptionActive"] === 1 ? false : true} size="sm" color="blueSky" className={classes.marginRight} onClick={() => this.setState({ showUpdateDeviceWizardForm: true, showUpdateDeviceWizardFormMode: "Update", selectedDevice: prop })}>Update</Button></Tooltip>
                                                        </div>
                                                    )
                                                };
                                            })}
                                        />
                                    </CardBody>
                                    </Card>
                            </GridItem>
                            </GridContainer>  
                        :
                        <GridContainer xs={12}>
                            {this.state.alert}
                                    <GridItem xs={12}>
                                        <Card>
                                            <CardBody>
                                            <Button
                                                color="transparent"
                                                round
                                                aria-label="Add Device"
                                                color="blueSky"
                                                onClick={() => this.setState({ showAddDeviceWizardForm: true, selectedDevice: null })}>
                                                <Add onClick={() => this.setState({ showAddDeviceWizardForm: true, selectedDevice: null })} /> Add Device
                                                </Button>
                                                {/*<Button
                                                    color="transparent"
                                                    round
                                                    aria-label="Add Vision Device"
                                                    color="blueSky"
                                                    onClick={() => this.setState({ showAddDeviceVisionWizardForm: true, selectedDevice: null })}>
                                                    <Add onClick={() => this.setState({ showAddDeviceVisionWizardForm: true, selectedDevice: null })} /> Add Vision Device
                                                </Button>*/}
                                                <hr/>
                                                <ReactTable
                                                    align="center"
                                                    columns={[
                                                        {
                                                            Header: "Name",
                                                            accessor: "DeviceName",
                                                            disableFilters: false
                                                        },
                                                        {
                                                            Header: "Device",
                                                            accessor: "DeviceType",
                                                            disableFilters: false
                                                        },
                                                        {
                                                            Header: "Fall Detection",
                                                            accessor: "FallEnabled",
                                                            disableFilters: false
                                                        },
                                                        {
                                                            Header: "Battery",
                                                            accessor: "BatteryLevel",
                                                            disableFilters: false
                                                        },
                                                        {
                                                            Header: "",
                                                            accessor: "Actions",
                                                            disableFilters: true,
                                                        }
                                                    ]}
                                                    data={devicesData.Devices.map((prop, key) => {
                                                        return {
                                                            id: key,
                                                            DeviceName: prop["DeviceName"],
                                                            DeviceType: prop["DeviceTypeDescription"],
                                                            FallEnabled: this.getDeviceFallDetectionValue(prop.DeviceType, prop.FallDetection),
                                                            BatteryLevel: prop.DeviceLocation !== null ? this.getDeviceBatteryLevelValue(prop["DeviceType"], prop.DeviceLocation.BatteryLevel + "%") : "",
                                                            Actions: (
                                                                <div className="actions-left">
                                                                    {prop["PayPalSubscriptionId"] !== null ? <Button size="sm" color="blueSky" onClick={() => this.showSubscription(classes, prop["SubscriptionTypePlanId"], prop["PayPalSubscriptionActive"], prop["PayPalSubscriptionId"])}>{prop["PayPalSubscriptionActive"] === null ? "PAUSED" : prop["PayPalSubscriptionActive"] === 1 ? "ACTIVE" : "PAUSED"}</Button> : <button style={payPalSmallButtonStyle} onClick={() => this.launchPayment(prop)}><img src={PayPal} height="30px" width="60px" onClick={() => this.launchPayment(prop)} /></button>}
                                                                    <Tooltip title="Update Device"><Button disabled={prop["PayPalSubscriptionActive"] === null ? true : prop["PayPalSubscriptionActive"] === 1 ? false : true} size="sm" color="blueSky" className={classes.marginRight} onClick={() => this.setState({ showUpdateDeviceWizardForm: true, showUpdateDeviceWizardFormMode: "Update", selectedDevice: prop })}>Update</Button></Tooltip>
                                                                    {prop["DeviceType"].toLowerCase() === "vision" && <Tooltip title="Log"><Button size="sm" color="blueSky" className={classes.marginRight} onClick={() => this.setState({ showComputerVisionLog: true, selectedDevice: prop })}>Log</Button></Tooltip>}
                                                                </div>
                                                            )
                                                        };
                                                    })}
                                                />
                                            </CardBody>
                                        </Card>
                                    </GridItem>
                                </GridContainer>  
                    :
                        this.state.mobilePortaitMode === true ?
                        <GridContainer xs={12}>
                            <GridItem xs={12}>
                                <Card>
                                    <CardBody>
                                        <Button
                                            color="transparent"
                                            round
                                            aria-label="Add SOS Pendant"
                                            color="blueSky"
                                            onClick={() => this.setState({ showAddDeviceWizardForm: true, selectedDevice: null })}>
                                            <Add onClick={() => this.setState({ showAddDeviceWizardForm: true, selectedDevice: null })} /> Add SOS Pendant
                                        </Button>
                                        <Button
                                            color="transparent"
                                            round
                                            aria-label="Add Vision Device"
                                            color="blueSky"
                                            onClick={() => this.setState({ showAddDeviceVisionWizardForm: true, selectedDevice: null })}>
                                            <Add onClick={() => this.setState({ showAddDeviceVisionWizardForm: true, selectedDevice: null })} /> Add Vision Device
                                        </Button>
                                        <hr />
                                        <ReactTable
                                            align="center"
                                            columns={[
                                                {
                                                    Header: "Name",
                                                    accessor: "DeviceName",
                                                    headerFontSize: 'smaller',
                                                    disableFilters: false,
                                                    minWidth: 80
                                                },
                                                {
                                                    Header: "Battery",
                                                    accessor: "BatteryLevel",
                                                    headerFontSize: 'smaller',
                                                    disableFilters: false,
                                                    minWidth: 60
                                                },
                                                {
                                                    Header: "",
                                                    accessor: "Actions",
                                                    headerFontSize: 'smaller',
                                                    disableFilters: true,
                                                    minWidth: 80
                                                }
                                            ]}
                                            data={emptyObjArray.map((prop, key) => {
                                                return {
                                                    id: key,
                                                    DeviceName: prop["DeviceName"],
                                                    BatteryLevel: prop.DeviceLocation !== null ? this.getDeviceBatteryLevelValue(prop.DeviceType, prop.DeviceLocation.BatteryLevel + "%") : "",
                                                    Actions: prop["Actions"]
                                                };
                                            })}
                                        />
                                        <br /><small style={{ color: 'red' }}>No devices exist.  Add a device to your account.</small>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>  
                        :
                        <GridContainer>
                                <GridItem xs={12}>
                                    <Card>
                                        <CardBody>
                                            <Button
                                                color="transparent"
                                                round
                                                aria-label="Add SOS Pendant"
                                                color="blueSky"
                                                onClick={() => this.setState({ showAddDeviceWizardForm: true, selectedDevice: null })}>
                                                <Add onClick={() => this.setState({ showAddDeviceWizardForm: true, selectedDevice: null })} /> Add SOS Pendant
                                            </Button>
                                            <Button
                                                color="transparent"
                                                round
                                                aria-label="Add Vision Device"
                                                color="blueSky"
                                                onClick={() => this.setState({ showAddDeviceVisionWizardForm: true, selectedDevice: null })}>
                                                <Add onClick={() => this.setState({ showAddDeviceVisionWizardForm: true, selectedDevice: null })} /> Add Vision Device
                                            </Button>
                                            <hr />
                                            <ReactTable
                                                align="center"
                                                columns={[
                                                    {
                                                        Header: "Name",
                                                        accessor: "DeviceName",
                                                        disableFilters: true
                                                    },
                                                    {
                                                        Header: "Device",
                                                        accessor: "DeviceType",
                                                        disableFilters: false
                                                    },
                                                    {
                                                        Header: "Fall Detection",
                                                        accessor: "FallEnabled",
                                                        disableFilters: true
                                                    },
                                                    {
                                                        Header: "Battery",
                                                        accessor: "BatteryLevel",
                                                        disableFilters: true
                                                    },
                                                    {
                                                        Header: "",
                                                        accessor: "Actions",
                                                        disableFilters: true,
                                                    }
                                                ]}
                                                data={emptyObjArray.map((prop, key) => {
                                                    return {
                                                        id: key,
                                                        DeviceName: prop["DeviceName"],
                                                        DeviceType: prop["DeviceTypeDescription"],
                                                        FallEnabled: prop["FallEnabled"],
                                                        BatteryLevel: prop.DeviceLocation !== null ? this.getDeviceBatteryLevelValue(prop["DeviceType"], prop.DeviceLocation.BatteryLevel + "%") : "",
                                                        Actions: prop["Actions"]
                                                    };
                                                })}
                                            />
                                            <br/><small style={{ color: 'red' }}>No devices exist.  Add a device to your account.</small>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                            </GridContainer>
            );
        }
    }
}

export default withStyles(style)(Devices);