import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import Close from "@material-ui/icons/Close";
import LockOutline from "@material-ui/icons/LockOutlined";

// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import CustomInput from "../../../components/CustomInput/CustomInput.js";
import Button from "../../../components/CustomButtons/Button.js";
import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import CardFooter from "../../../components/Card/CardFooter.js";

import styles from "../../../assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import validationFormStyles from "../../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

const useStyles = makeStyles(styles);
const useValidationFormStyles = makeStyles(validationFormStyles);

export default function ResetPasswordPage() {
    const history = useHistory();
    const classes = useStyles();
    const validationClasses = useValidationFormStyles();
    const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");

    function handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }

    const verifyEmail = (value) => {
        if (value === null) return false;

        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(value)) {
            return true;
        }
        return false;
    };

    const verifyLength = (value, length) => {
        if (value === null) return false;

        if (value.length >= length) {
            return true;
        }
        return false;
    };

    function getQueryVariable(variable) {
        var query = window.location.search.substring(1);
        console.log(query)
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] == variable) { return pair[1]; }
        }
        return (false);
    }

    React.useEffect(() => {
    let id = setTimeout(function() {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

    const resetToken = decodeURIComponent(getQueryVariable('resetToken') || '');
    const username = decodeURIComponent(getQueryVariable('username') || '');

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [email, setEmail] = useState(username);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [EmailRequiredState, setEmailRequiredState] = React.useState("");
    const [PasswordRequiredState, setPasswordRequiredState] = React.useState("");
    const [ConfirmPasswordRequiredState, setConfirmPasswordRequiredState] = React.useState("");

    function verifyFormFields() {
        var result = true;

        if (verifyEmail(email) == false) {
            setEmailRequiredState("error");
        }
        if (password === "") {
            setPasswordRequiredState("error");
            result = false;
        } else {
            if (password !== confirmPassword) {
                setPasswordRequiredState("error");
                setConfirmPasswordRequiredState("error");
                result = false;
            }
        }

        return result
    }

    const onClickSendResetEmail = (event) => {
        event.preventDefault();

        if (verifyEmail(email) === false) {
            setError("The Email... field must be a valid email address.");
        } else {
            setLoading(true);

            const apiURL = process.env.REACT_APP_API_BASE_URL;
            const subscriptionKey = process.env.REACT_APP_API_Subscription_Key;
            const headers = { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': subscriptionKey };

            fetch(apiURL + 'api/Account/SendChangePassword', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({ Email: email.trim() })
            })
                .then(handleErrors)
                .then(response => {
                    setLoading(false);
                    setError("A password reset email has been sent to your inbox.");
                })
                .catch(error => {
                    setLoading(false);
                    setError("Something went wrong. Please check your email. Contact Tunstall support for further assistance.");
                });
        }
    }

    const onClickReset = (event) => {
        event.preventDefault();

        if (verifyFormFields() == false) {
            if (password !== confirmPassword) 
                setError("New password and Confirm new password do not match.");
        }
        else {
            setLoading(true);

            const apiURL = process.env.REACT_APP_API_BASE_URL;
            const subscriptionKey = process.env.REACT_APP_API_Subscription_Key;
            const headers = { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': subscriptionKey };

            const json = {
                Email: email.trim(),
                ResetToken: resetToken,
                NewPassword: password
            };

            fetch(apiURL + 'api/Account/ResetPassword', {
                method: 'POST',
                body: JSON.stringify(json),
                headers: headers
            })
                .then(handleErrors)
                .then(response => response.json())
                .then(data => {
                    setLoading(false);
                    if (data.Succeeded)
                        login(email, password)
                    else
                        setError("Password reset failed. " + data.Errors[0]);
                })
                .catch(error => {
                    setLoading(false);
                    setError("Something went wrong. Please check your login or ask your Tunstall administration for assistance.");
                });
        }
    }

    const login = (email, password) => {
        const apiURL = process.env.REACT_APP_API_BASE_URL;
        const subscriptionKey = process.env.REACT_APP_API_SUBSCRIPTION_KEY;
        const headers = { 'Content-Type': 'text/plain', 'Ocp-Apim-Subscription-Key': subscriptionKey };

        fetch(apiURL + 'Token', {
            method: 'POST',
            body: "grant_type=password&username=" + email.trim() + "&password=" + password,
            headers: headers
        })
            .then(handleErrors)
            .then(response => response.json())
            .then(response => {
                localStorage.setItem("careXactSOS_BearerToken", response.access_token);
                localStorage.setItem("careXactSOS_ApiURL", apiURL);
                localStorage.setItem("careXactSOS_SubscriptionKey", subscriptionKey);
                setLoading(false);
                history.push("/");
            })
            .catch(error => {
                localStorage.removeItem("careXactSOS_BearerToken");
                setLoading(false);
                setError("Something went wrong. Please try to login again using your new email and password.");
                console.log(error.message);
            });
    }

    return (
        resetToken === '' ?
            <div className={classes.container}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={6} md={4}>
                        <Card login className={classes[cardAnimaton]}>
                            <CardHeader
                                className={`${classes.cardHeader} ${classes.textCenter}`}
                                color="blueSky"
                            >
                                <h1 className={classes.cardTitle}>Tunstall SOS</h1>
                                <h4 className={classes.cardTitle}>Reset Password</h4>
                            </CardHeader>
                            <CardBody>
                                <CustomInput
                                    labelText="Email..."
                                    id="email"
                                    error={EmailRequiredState === "error"}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: event => {
                                            verifyEmail(event.target.value) === true ? setEmailRequiredState("success") : setEmailRequiredState("error");
                                            setEmail(event.target.value);
                                        },
                                        value: email,
                                        autoComplete: "off",
                                        endAdornment: EmailRequiredState === "error" ? (<InputAdornment position="end"><Close className={validationClasses.danger} /></InputAdornment>) : (<InputAdornment position="end"><Email className={classes.inputAdornmentIcon} /></InputAdornment>)
                                    }}
                                />
                            </CardBody>
                            <CardFooter className={classes.justifyContentCenter}>
                                <Button color="rose" simple size="lg" block onClick={onClickSendResetEmail} disabled={loading}>
                                    {loading ? "Sending Email..." : "Reset"}
                                </Button>
                                {error && <><br /><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
                            </CardFooter>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
            :
            <div className={classes.container}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={6} md={4}>
                        <Card login className={classes[cardAnimaton]}>
                            <CardHeader
                                className={`${classes.cardHeader} ${classes.textCenter}`}
                                color="blueSky"
                            >
                                <h1 className={classes.cardTitle}>CareXact</h1>
                                <h4 className={classes.cardTitle}>Reset Password</h4>
                            </CardHeader>
                            <CardBody>
                                <CustomInput
                                    labelText="Email..."
                                    id="email"
                                    error={EmailRequiredState === "error"}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: event => {
                                            verifyEmail(event.target.value) === true ? setEmailRequiredState("success") : setEmailRequiredState("error");
                                            setEmail(event.target.value);
                                        },
                                        value: email,
                                        autoComplete: "off",
                                        endAdornment: EmailRequiredState === "error" ? (<InputAdornment position="end"><Close className={validationClasses.danger} /></InputAdornment>) : (<InputAdornment position="end"><Email className={classes.inputAdornmentIcon} /></InputAdornment>)
                                    }}
                                />
                                <CustomInput
                                    labelText="New Password"
                                    id="password"
                                    error={PasswordRequiredState === "error"}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: event => {
                                            verifyLength(event.target.value, 1) === true ? setPasswordRequiredState("success") : setPasswordRequiredState("error");
                                            setPassword(event.target.value);
                                        },
                                        endAdornment: PasswordRequiredState === "error" ? (<InputAdornment position="end"><Close className={validationClasses.danger} /></InputAdornment>) : (<InputAdornment position="end"><LockOutline className={classes.inputAdornmentIcon} /></InputAdornment>),
                                        type: "password",
                                        autoComplete: "off"
                                    }}
                                />
                                <CustomInput
                                    labelText="Confirm new password"
                                    id="confirmPassword"
                                    error={ConfirmPasswordRequiredState === "error"}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: event => {
                                            verifyLength(event.target.value, 1) === true ? setConfirmPasswordRequiredState("success") : setConfirmPasswordRequiredState("error");
                                            setConfirmPassword(event.target.value);
                                        },
                                        endAdornment: ConfirmPasswordRequiredState === "error" ? (<InputAdornment position="end"><Close className={validationClasses.danger} /></InputAdornment>) : (<InputAdornment position="end"><LockOutline className={classes.inputAdornmentIcon} /></InputAdornment>),
                                        type: "password",
                                        autoComplete: "off"
                                    }}
                                />
                            </CardBody>
                            <CardFooter className={classes.justifyContentCenter}>
                                <Button color="rose" simple size="lg" block onClick={onClickReset} disabled={loading}>
                                    {loading ? "Setting new password..." : "Reset"}
                                </Button>
                                {error && <><br /><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
                            </CardFooter>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
  );
}