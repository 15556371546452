import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import LockOutline from "@material-ui/icons/LockOutlined";

// tell webpack this js file uses the image
import SignUp from "../../../assets/img/sign-up-button.png";

// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import CustomInput from "../../../components/CustomInput/CustomInput.js";
import Button from "../../../components/CustomButtons/Button.js";
import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";
import CardHeader from "../../../components/Card/CardHeader.js";

import styles from "../../../assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const signupButtonStyle = { height: "60px", width: "60px", padding: "0px 0px 0px 0px", borderStyle: "none", borderRadius: "40px" }

const useStyles = makeStyles(styles);

export default function LoginPage() {
    const history = useHistory();
    const classes = useStyles();
    const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");

    function handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response.json();
    }

    React.useEffect(() => {
        let id = setTimeout(function() {
        setCardAnimation("");
        }, 700);
        // Specify how to clean up after this effect:
        return function cleanup() {
            window.clearTimeout(id);
        };
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleFormChanges = (event) => {
        if (event.target.id === "email") setEmail(event.target.value);
        if (event.target.id === "password") setPassword(event.target.value);
    };

    const handleSignUp = (event) => {
        event.preventDefault();
        history.push("/auth/sign-up");
    };

    const handleResetClick = (event) => {
        event.preventDefault();
        history.push("/auth/reset-password");
    };

    const onLoginClick = (event) => {
        event.preventDefault();
        setLoading(true);

        const apiURL = process.env.REACT_APP_API_BASE_URL;
        const subscriptionKey = process.env.REACT_APP_API_SUBSCRIPTION_KEY;
        const headers = { 'Content-Type': 'text/plain', 'Ocp-Apim-Subscription-Key': subscriptionKey };
        
        fetch(apiURL + 'Token', {
            method: 'POST',
            body: "grant_type=password&username=" + email.trim() + "&password=" + password,
            headers: headers
        })
        .then(handleErrors)
        .then(response => {
                localStorage.setItem("careXactSOS_BearerToken", response.access_token);
                localStorage.setItem("careXactSOS_ApiURL", apiURL);
                localStorage.setItem("careXactSOS_SubscriptionKey", subscriptionKey);

                setLoading(false);

            if (localStorage.getItem("careXactSOS_lastURL") !== null)
                history.replace(localStorage.getItem("careXactSOS_lastURL").replace(window.location.origin, ""));
                else
                    history.push("/");
        })
        .catch(error => {
            localStorage.removeItem("careXactSOS_BearerToken");
            setLoading(false);
            setError("Something went wrong. Please check your email and password then try again, or use the reset password feature above.");
            console.log(error.message);
        });
    }

    return (
        <div className={classes.container}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={6} md={4}>
                 <form onChange={handleFormChanges}>
                    <Card login className={classes[cardAnimaton]}>
                      <CardHeader
                        className={`${classes.cardHeader} ${classes.textCenter}`}
                        color="blueSky">  
                                <h1 className={classes.cardTitle}>Tunstall</h1>
                                <h3 className={classes.cardTitle}>Gem4 SOS</h3>
                                <h4 className={classes.cardTitle}>Log in</h4>
                      </CardHeader>
                      <CardBody>
                            <CustomInput
                              labelText="Email..."
                              id="email"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                onKeyPress: (event) => (event.key === 'Enter' ? onLoginClick(event) : null),
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Email className={classes.inputAdornmentIcon} />
                                  </InputAdornment>
                                )
                              }}
                            />
                            <CustomInput
                              labelText="Password"
                              id="password"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                onKeyPress: (event) => (event.key === 'Enter' ? onLoginClick(event) : null),
                                endAdornment: (
                                    <InputAdornment position="end">
                                          <LockOutline className={classes.inputAdornmentIcon} />
                                    </InputAdornment>
                                ),
                                type: "password",
                                autoComplete: "off"
                              }}
                            />
                            <a href="#" onClick={handleResetClick}>Forgot your password?</a>
                            <font color="#FFFFFF" size="1"><i>Build : {process.env.REACT_APP_BUILD_VERSION}</i></font>  
                            <Button color="rose" simple size="lg" block onClick={onLoginClick} disabled={loading}>
                            {loading ? "Verifying..." : "Log in"}
                            </Button>
                            {error && <><br /><small style={{ color: 'red' }}>{error}</small><br /></>}  
                            <button style={signupButtonStyle}><img src={SignUp} height="60px" width="60px" onClick={event => handleSignUp(event)} /></button>
                      </CardBody>         
                    </Card>
                  </form>
                </GridItem>
            </GridContainer>
        </div>
  );
}