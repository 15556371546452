import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

// core components
import Wizard from "../../../components/Wizard/Wizard.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";

import FallAlarmStep from "./DeviceDetailsWizardSteps/FallAlarm.js";
import EmergencyContactsStep from "./DeviceDetailsWizardSteps/EmergencyContacts.js";
import ContactDetailsStep from "./DeviceDetailsWizardSteps/ContactDetails.js";
import DeviceNameStep from "./DeviceDetailsWizardSteps/DeviceName.js";

import { makeStyles } from "@material-ui/core/styles";
import alertStyles from "../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useAlertStyles = makeStyles(alertStyles);

export default function WizardUpdateDevice(props) {
    const alertClasses = useAlertStyles();

    const hideAlert = () => {
        setAlert(null);
    };

    const [Saving, setSaving] = React.useState(false);
    const [alert, setAlert] = React.useState(null);

    const showAlert = (title, message) => {
        setAlert(
            <SweetAlert
                error
                style={{ display: "block", marginTop: "-100px", width: "400px" }}
                title={title}
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}>
                {message}
            </SweetAlert>
        );
    };

    const handleFinish = async (event) => {
        if (Saving !== true) {
            setSaving(true);
            var existingDeviceRecord = JSON.parse(localStorage.getItem("careXactSOS_Device"));

            var fallDetectionStep = event["fallDetection"];
            var emergencyNumbersStep = event["emergencyNumbers"];
            var contactDetailsStep = event["contactDetails"];
            var deviceNameStep = event["deviceName"];

            var existingDeviceUpdated = false;
            var deviceNameChanged = false;
            var partyRecordChanged = false;
            var addressChanged = false;
            var fallDetectionChanged = false;
            var emergencyNumber1Changed = false;
            var emergencyNumber2Changed = false;
            var emergencyNumber3Changed = false;
            var emergencyNumber4Changed = false;

            var emergency1Index = -1;
            var emergency2Index = -1;
            var emergency3Index = -1;
            var emergency4Index = -1;

            // Merge update into existing device record
            if (fallDetectionStep !== undefined) {
                var fallEnabled = fallDetectionStep.fallEnabled ? "Y" : "N";
                if (existingDeviceRecord.FallDetection !== null)
                    if (existingDeviceRecord.FallDetection[0].Enabled !== fallEnabled) fallDetectionChanged = true;

                if (fallDetectionChanged === false)
                    if (existingDeviceRecord.FallDetection[0].Sensitivity !== fallDetectionStep.sensitivity) fallDetectionChanged = true;
            }

            if (emergencyNumbersStep !== undefined) {

                if (existingDeviceRecord.AuthorizedNumbers !== null)
                    if (existingDeviceRecord.AuthorizedNumbers !== undefined) {
                        var i = 0;
                        for (i = 0; i < existingDeviceRecord.AuthorizedNumbers.length; i++) {
                            var authorizedNumber = existingDeviceRecord.AuthorizedNumbers[i];
                            if (authorizedNumber.Serial === 0) emergency1Index = i;
                            if (authorizedNumber.Serial === 1) emergency2Index = i;
                            if (authorizedNumber.Serial === 2) emergency3Index = i;
                            if (authorizedNumber.Serial === 3) emergency4Index = i;
                        }
                    }

                if (emergency1Index === -1) {
                    // Serial === 1 not found in api sourced array but we have data in UI state so we are adding a phone number
                    emergencyNumber1Changed = true;
                }
                else {
                    // is a number being changed?  Check each of the fields in state against those sourced from api
                    var phoneNumber1ReceiveCalls = emergencyNumbersStep.PhoneNumber1ReceiveCalls ? "Y" : "N";
                    var phoneNumber1ReceiveSMS = emergencyNumbersStep.PhoneNumber1ReceiveSMS ? "Y" : "N";

                    if (existingDeviceRecord.AuthorizedNumbers[emergency1Index].AcceptPhoneCall !== phoneNumber1ReceiveCalls) emergencyNumber1Changed = true;
                    if (existingDeviceRecord.AuthorizedNumbers[emergency1Index].AcceptSMS !== phoneNumber1ReceiveSMS) emergencyNumber1Changed = true;
                    if (existingDeviceRecord.AuthorizedNumbers[emergency1Index].AuthorizedNumber !== emergencyNumbersStep.PhoneNumber1) emergencyNumber1Changed = true;
                }

                if (emergency2Index === -1) {
                    // Serial === 2 not found in api array but we have data in UI state so we are adding a number
                    emergencyNumber2Changed = true;
                }
                else {
                    // changing a number?
                    var phoneNumber2ReceiveCalls = emergencyNumbersStep.PhoneNumber2ReceiveCalls ? "Y" : "N";
                    var phoneNumber2ReceiveSMS = emergencyNumbersStep.PhoneNumber2ReceiveSMS ? "Y" : "N";
                    if (existingDeviceRecord.AuthorizedNumbers[emergency2Index].AcceptPhoneCall !== phoneNumber2ReceiveCalls) emergencyNumber2Changed = true;
                    if (existingDeviceRecord.AuthorizedNumbers[emergency2Index].AcceptSMS !== phoneNumber2ReceiveSMS) emergencyNumber2Changed = true;
                    if (existingDeviceRecord.AuthorizedNumbers[emergency2Index].AuthorizedNumber !== emergencyNumbersStep.PhoneNumber2) emergencyNumber2Changed = true;
                }

                if (emergency3Index === -1) {
                    // Serial === 3 not found in api array but we have data in UI state so we are adding a number
                    emergencyNumber3Changed = true;
                }
                else {
                    // is a number being changed?
                    var phoneNumber3ReceiveCalls = emergencyNumbersStep.PhoneNumber3ReceiveCalls ? "Y" : "N";
                    var phoneNumber3ReceiveSMS = emergencyNumbersStep.PhoneNumber3ReceiveSMS ? "Y" : "N";

                    if (existingDeviceRecord.AuthorizedNumbers[emergency3Index].AcceptPhoneCall !== phoneNumber3ReceiveCalls) emergencyNumber3Changed = true;
                    if (existingDeviceRecord.AuthorizedNumbers[emergency3Index].AcceptSMS !== phoneNumber3ReceiveSMS) emergencyNumber3Changed = true;
                    if (existingDeviceRecord.AuthorizedNumbers[emergency3Index].AuthorizedNumber !== emergencyNumbersStep.PhoneNumber3) emergencyNumber3Changed = true;
                }

                if (emergency4Index === -1) {
                    // Serial === 4 not found in api array but we have data in UI state so we are adding a number
                    emergencyNumber4Changed = true;
                }
                else {
                    // is a number being changed?
                    var phoneNumber4ReceiveCalls = emergencyNumbersStep.PhoneNumber4ReceiveCalls ? "Y" : "N";
                    var phoneNumber4ReceiveSMS = emergencyNumbersStep.PhoneNumber4ReceiveSMS ? "Y" : "N";

                    if (existingDeviceRecord.AuthorizedNumbers[emergency4Index].AcceptPhoneCall !== phoneNumber4ReceiveCalls) emergencyNumber4Changed = true;
                    if (existingDeviceRecord.AuthorizedNumbers[emergency4Index].AcceptSMS !== phoneNumber4ReceiveSMS) emergencyNumber4Changed = true;
                    if (existingDeviceRecord.AuthorizedNumbers[emergency4Index].AuthorizedNumber !== emergencyNumbersStep.PhoneNumber4) emergencyNumber4Changed = true;
                }
            }

            if (contactDetailsStep !== undefined) {
                // update an existing entry
                if (existingDeviceRecord.Party.FirstName !== contactDetailsStep.FirstName) partyRecordChanged = true;
                if (existingDeviceRecord.Party.LastName !== contactDetailsStep.LastName) partyRecordChanged = true;
                if (existingDeviceRecord.Party.PhoneNumber !== contactDetailsStep.PhoneNumber) partyRecordChanged = true;
                if (existingDeviceRecord.Party.StreetNumber !== contactDetailsStep.StreetNumber) partyRecordChanged = true;
                if (existingDeviceRecord.Party.StreetName !== contactDetailsStep.StreetName) partyRecordChanged = true;
                if (existingDeviceRecord.Party.StreetType !== contactDetailsStep.StreetType) partyRecordChanged = true;
                if (existingDeviceRecord.Party.Suburb !== contactDetailsStep.Suburb) partyRecordChanged = true;
                if (existingDeviceRecord.Party.PostCode !== contactDetailsStep.PostCode) partyRecordChanged = true;
                if (existingDeviceRecord.Party.State !== contactDetailsStep.State) partyRecordChanged = true;
                if (existingDeviceRecord.Party.Country !== contactDetailsStep.Country) partyRecordChanged = true;

                var existingAddress = existingDeviceRecord.Party.StreetNumber + existingDeviceRecord.Party.StreetName + existingDeviceRecord.Party.StreetType + existingDeviceRecord.Party.Suburb + existingDeviceRecord.Party.State + existingDeviceRecord.Party.Country;
                var contactDetailsStepAddress = contactDetailsStep.StreetNumber + contactDetailsStep.StreetName + contactDetailsStep.StreetType + contactDetailsStep.Suburb + contactDetailsStep.State + contactDetailsStep.Country;

                if (existingAddress != contactDetailsStepAddress) addressChanged = true;
            }

            if (deviceNameStep !== undefined) {
                if (existingDeviceRecord.DeviceName !== deviceNameStep.DeviceName) {
                    deviceNameChanged = true;
                    // do this so we know to trigger a party entity commit.  The API updates the party and device in the one stored proc
                    partyRecordChanged = true;
                }
            }

            // if any changes at all, then flag the party device record has changed.  This will set the Device.DateTimeModified so users don't overwrite each others changes
            if (deviceNameChanged || fallDetectionChanged || emergencyNumber1Changed || emergencyNumber2Changed || emergencyNumber3Changed || emergencyNumber4Changed) {
                partyRecordChanged = true;
            }

            if (deviceNameChanged) existingDeviceUpdated = true;
            if (partyRecordChanged) existingDeviceUpdated = true;
            if (fallDetectionChanged) existingDeviceUpdated = true;
            if (emergencyNumber1Changed) existingDeviceUpdated = true;
            if (emergencyNumber2Changed) existingDeviceUpdated = true;
            if (emergencyNumber3Changed) existingDeviceUpdated = true;
            if (emergencyNumber4Changed) existingDeviceUpdated = true;

            // no changes at all so close without calling the API to refresh the UI
            if (existingDeviceUpdated == false) props.closeFormNoRefresh();

            var existingPhoneNumber1 = {
                AuthorizedNumber: "",
                AcceptSMS: false,
                AcceptPhoneCall: false
            };
            var existingPhoneNumber2 = {
                AuthorizedNumber: "",
                AcceptSMS: false,
                AcceptPhoneCall: false
            };
            var existingPhoneNumber3 = {
                AuthorizedNumber: "",
                AcceptSMS: false,
                AcceptPhoneCall: false
            };
            var existingPhoneNumber4 = {
                AuthorizedNumber: "",
                AcceptSMS: false,
                AcceptPhoneCall: false
            };

            // Get existing phone numbers
            if (emergencyNumber1Changed === false) {
                if (existingDeviceRecord.AuthorizedNumbers !== null)
                    if (existingDeviceRecord.AuthorizedNumbers !== undefined) {
                        try {
                            existingPhoneNumber1.AuthorizedNumber = existingDeviceRecord.AuthorizedNumbers[emergency1Index].AuthorizedNumber;
                            existingPhoneNumber1.AcceptSMS = existingDeviceRecord.AuthorizedNumbers[emergency1Index].AcceptSMS;
                            existingPhoneNumber1.AcceptPhoneCall = existingDeviceRecord.AuthorizedNumbers[emergency1Index].AcceptPhoneCall;
                        }
                        catch {
                            // do nothing.  this means the existing record did not exist
                        }
                    }
            }

            if (emergencyNumber2Changed === false) {
                if (existingDeviceRecord.AuthorizedNumbers !== null)
                    if (existingDeviceRecord.AuthorizedNumbers !== undefined) {
                        try {
                            existingPhoneNumber2.AuthorizedNumber = existingDeviceRecord.AuthorizedNumbers[emergency2Index].AuthorizedNumber;
                            existingPhoneNumber2.AcceptSMS = existingDeviceRecord.AuthorizedNumbers[emergency2Index].AcceptSMS;
                            existingPhoneNumber2.AcceptPhoneCall = existingDeviceRecord.AuthorizedNumbers[emergency2Index].AcceptPhoneCall;
                        }
                        catch {
                            // do nothing.  this means the existing record did not exist
                        }
                    }
            }

            if (emergencyNumber3Changed === false) {
                if (existingDeviceRecord.AuthorizedNumbers !== null)
                    if (existingDeviceRecord.AuthorizedNumbers !== undefined) {
                        try {
                            existingPhoneNumber3.AuthorizedNumber = existingDeviceRecord.AuthorizedNumbers[emergency3Index].AuthorizedNumber;
                            existingPhoneNumber3.AcceptSMS = existingDeviceRecord.AuthorizedNumbers[emergency3Index].AcceptSMS;
                            existingPhoneNumber3.AcceptPhoneCall = existingDeviceRecord.AuthorizedNumbers[emergency3Index].AcceptPhoneCall;
                        }
                        catch {
                            // do nothing.  this means the existing record did not exist
                        }
                    }
            }

            if (emergencyNumber4Changed === false) {
                if (existingDeviceRecord.AuthorizedNumbers !== null)
                    if (existingDeviceRecord.AuthorizedNumbers !== undefined) {
                        try {
                            existingPhoneNumber4.AuthorizedNumber = existingDeviceRecord.AuthorizedNumbers[emergency4Index].AuthorizedNumber;
                            existingPhoneNumber4.AcceptSMS = existingDeviceRecord.AuthorizedNumbers[emergency4Index].AcceptSMS;
                            existingPhoneNumber4.AcceptPhoneCall = existingDeviceRecord.AuthorizedNumbers[emergency4Index].AcceptPhoneCall;
                        }
                        catch {
                            // do nothing.  this means the existing record did not exist
                        }
                    }
            }

            if (existingDeviceUpdated === false) {
                props.closeFormNoRefresh();  // no change
            }
            else {
                // Apply form data changes records in state
                const json = {
                    DeviceId: existingDeviceRecord.DeviceId,
                    DeviceName: deviceNameChanged ? deviceNameStep.DeviceName : existingDeviceRecord.DeviceName,
                    DeviceDateTimeModified: existingDeviceRecord.DeviceDateTimeModified,
                    ProductCode: existingDeviceRecord.ProductCode,
                    Party: {
                        PartyId: existingDeviceRecord.Party.PartyId,
                        FirstName: partyRecordChanged ? contactDetailsStep.FirstName : existingDeviceRecord.Party.FirstName,
                        LastName: partyRecordChanged ? contactDetailsStep.LastName : existingDeviceRecord.Party.LastName,
                        PhoneNumber: partyRecordChanged ? contactDetailsStep.PhoneNumber : existingDeviceRecord.Party.PhoneNumber,
                        StreetNumber: addressChanged ? contactDetailsStep.StreetNumber : existingDeviceRecord.Party.StreetNumber,
                        StreetName: addressChanged ? contactDetailsStep.StreetName : existingDeviceRecord.Party.StreetName,
                        StreetType: addressChanged ? contactDetailsStep.StreetType : existingDeviceRecord.Party.StreetType,
                        Suburb: addressChanged ? contactDetailsStep.Suburb : existingDeviceRecord.Party.Suburb,
                        State: addressChanged ? contactDetailsStep.State : existingDeviceRecord.Party.State,
                        PostCode: addressChanged ? contactDetailsStep.PostCode : existingDeviceRecord.Party.PostCode,
                        Country: addressChanged ? contactDetailsStep.Country : existingDeviceRecord.Party.Country,
                        AddressLatitude: addressChanged ? contactDetailsStep.AddressLatitude : existingDeviceRecord.Party.AddressLatitude,
                        AddressLongitude: addressChanged ? contactDetailsStep.AddressLongitude : existingDeviceRecord.Party.AddressLongitude,
                        AddressUpdated: addressChanged,
                        EntityUpdated: partyRecordChanged
                    },
                    AuthorizedNumbers: [
                        {
                            Serial: 0,
                            AuthorizedNumber: emergencyNumber1Changed ? emergencyNumbersStep.PhoneNumber1 : existingPhoneNumber1.AuthorizedNumber,
                            AcceptSMS: emergencyNumber1Changed ? emergencyNumbersStep.PhoneNumber1ReceiveSMS ? "Y" : "N" : existingPhoneNumber1.AcceptSMS,
                            AcceptPhoneCall: emergencyNumber1Changed ? emergencyNumbersStep.PhoneNumber1ReceiveCalls ? "Y" : "N" : existingPhoneNumber1.AcceptPhoneCall,
                            EntityUpdated: emergencyNumber1Changed
                        },
                        {
                            Serial: 1,
                            AuthorizedNumber: emergencyNumber2Changed ? emergencyNumbersStep.PhoneNumber2 : existingPhoneNumber2.AuthorizedNumber,
                            AcceptSMS: emergencyNumber2Changed ? emergencyNumbersStep.PhoneNumber2ReceiveSMS ? "Y" : "N" : existingPhoneNumber2.AcceptSMS,
                            AcceptPhoneCall: emergencyNumber2Changed ? emergencyNumbersStep.PhoneNumber2ReceiveCalls ? "Y" : "N" : existingPhoneNumber2.AcceptPhoneCall,
                            EntityUpdated: emergencyNumber2Changed
                        },
                        {
                            Serial: 2,
                            AuthorizedNumber: emergencyNumber3Changed ? emergencyNumbersStep.PhoneNumber3 : existingPhoneNumber3.AuthorizedNumber,
                            AcceptSMS: emergencyNumber3Changed ? emergencyNumbersStep.PhoneNumber3ReceiveSMS ? "Y" : "N" : existingPhoneNumber3.AcceptSMS,
                            AcceptPhoneCall: emergencyNumber3Changed ? emergencyNumbersStep.PhoneNumber3ReceiveCalls ? "Y" : "N" : existingPhoneNumber3.AcceptPhoneCall,
                            EntityUpdated: emergencyNumber3Changed
                        },
                        {
                            Serial: 3,
                            AuthorizedNumber: emergencyNumber4Changed ? emergencyNumbersStep.PhoneNumber4 : existingPhoneNumber4.AuthorizedNumber,
                            AcceptSMS: emergencyNumber4Changed ? emergencyNumbersStep.PhoneNumber4ReceiveSMS ? "Y" : "N" : existingPhoneNumber4.AcceptSMS,
                            AcceptPhoneCall: emergencyNumber4Changed ? emergencyNumbersStep.PhoneNumber4ReceiveCalls ? "Y" : "N" : existingPhoneNumber4.AcceptPhoneCall,
                            EntityUpdated: emergencyNumber4Changed
                        }
                    ],
                    FallDetection: [
                        {
                            Enabled: fallDetectionChanged ? fallDetectionStep.fallEnabled ? "Y" : "N" : existingDeviceRecord.FallDetection !== null ? existingDeviceRecord.FallDetection[0].Enabled : "N",
                            Sensitivity: fallDetectionChanged ? fallDetectionStep.sensitivity : existingDeviceRecord.FallDetection[0].Sensitivity,
                            EntityUpdated: fallDetectionChanged
                        }
                    ]
                };

                const apiURL = localStorage.getItem("careXactSOS_ApiURL");
                const authHeader = "Bearer " + localStorage.getItem("careXactSOS_BearerToken");
                const subscriptionKey = localStorage.getItem("careXactSOS_SubscriptionKey");
                const headers = { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': subscriptionKey, 'Authorization': authHeader };
                var responseOk = true;

                fetch(apiURL + 'api/Device', {
                    method: 'POST',
                    body: JSON.stringify(json),
                    headers: headers
                })
                    .then(function (response) {
                        responseOk = response.ok;
                        return response.json();
                    })
                    .then(response => {
                        if (!responseOk)
                            throw Error(response.Message);

                        props.closeForm("UPDATE");
                    })
                    .catch(error => {
                        setSaving(false);
                        showAlert("Error", "Unable to update the device. " + error);
                        console.log(error.message);
                    });
            }
        }
    };

    const handleClose = async (event) => {
        props.closeFormNoRefresh();
    }

    return (
        <GridContainer justify="center">
            <GridItem xs={12} sm={12}>
                <Wizard
                    color="blueSky"
                    validate
                    steps={[
                        { stepName: "Device Name", stepComponent: DeviceNameStep, stepId: "deviceName" },
                        { stepName: "Fall Detection", stepComponent: FallAlarmStep, stepId: "fallDetection" },
                        { stepName: "Emergency Numbers", stepComponent: EmergencyContactsStep, stepId: "emergencyNumbers" },
                        { stepName: "User Details", stepComponent: ContactDetailsStep, stepId: "contactDetails" }
                    ]}
                    title=""
                    subtitle=""
                    //title="Update your device"
                    //subtitle="Configure the settings on your SOS pendant."
                    finishButtonClick={event => handleFinish(event)}
                    closeButtonClick={event => handleClose(event)}
                />
                {alert}
            </GridItem>
        </GridContainer>
    );
}
