import React, { Component } from 'react';
import { Map, Marker, Popup, TileLayer, ZoomControl } from 'react-leaflet';
import L, { latLngBounds } from 'leaflet';
import moment, { relativeTimeRounding } from 'moment';
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";

// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import Button from "../../../components/CustomButtons/Button.js";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import customCheckboxRadioSwitch from "../../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import customSelectStyle from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import alertStyles from "../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import info from "../../../assets/img/info.png";
import alertMarkerIcon from "../../../assets/img/map/alertMarker.svg";
import deviceMarkerIcon from "../../../assets/img/map/deviceMarker.svg";

import Fab from '@material-ui/core/Fab';

import Loader from 'react-loader-spinner';
import GridItem from '../../../components/Grid/GridItem.js';
import zIndex from '@material-ui/core/styles/zIndex';
const loaderStyle = { position: "fixed", top: "50%", left: "55%", transform: "translate(-50%, -50%)" }
const loader = (<div style={loaderStyle}> <Loader type="ThreeDots" color="#4cb5f5" width="200" height="200" /></div>);

const style = {
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center"
    },
    inputAdornmentIcon: {
        color: "#555"
    },
    choiche: {
        textAlign: "center",
        cursor: "pointer",
        marginTop: "20px"
    },
    ...customSelectStyle,
    ...customCheckboxRadioSwitch,
    ...alertStyles
};

const defaultConfig = {
    center: {
        lat: -24.846565,
        lng: 133.81485,
    },
    zoom: 5,
};

const position = [defaultConfig.center.lat, defaultConfig.center.lng]

const alertMarker = new L.Icon({
    iconUrl: require('../../../assets/img/map/alertMarker.svg'),
    iconAnchor: [30, 80],
    popupAnchor: [10, -81],
    iconSize: [57, 80]
});

const deviceMarker = new L.Icon({
    iconUrl: require('../../../assets/img/map/deviceMarker.svg'),
    iconAnchor: [28, 80],
    popupAnchor: [10, -81],
    iconSize: [57, 80]
});

const infoButtonStyle = {
    margin: 0,
    top: 90,
    right: 50,
    bottom: 'auto',
    left: 'auto',
    position: 'fixed',
    zIndex: 1000
};

class DeviceLocator extends Component {
    interval = null;

    constructor(props) {
        super(props);

        var showAlertsSetting = localStorage.getItem("CareXactSOS_DeviceLocator_ShowAlerts");

        if (showAlertsSetting === null) {
            showAlertsSetting = false;
        } else {
            // localstorage stores boolean as strings "true", "false".  Use JSON.parse to cast to boolean
            showAlertsSetting = JSON.parse(showAlertsSetting);
        }

        localStorage.setItem("CareXactSOS_DeviceLocator_ShowAlerts", showAlertsSetting);

        this.state = {
            devicesData: null,
            alertData: null,
            selectedPartyId: null,
            showAlerts: showAlertsSetting,
            isFindMeButtonDisabled: false,
            isTestMeButtonDisabled: false,
            alert: null,
            zoom: 18,
            height: 0,
            currentSelectedDeviceAddress : null
        };
    }

    componentWillMount() {
        this.updateDimensions();
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions.bind(this));
        window.addEventListener('orientationchange', this.updateDimensions.bind(this));
        // refresh the map from the API after 10 minutes, 600,000ms
        this.interval = setInterval(this.refreshData, 600000);
        this.refreshData();
    }

    componentWillUnmount() {
        window.removeEventListener('orientationchange', this.updateDimensions.bind(this));
        window.removeEventListener("resize", this.updateDimensions.bind(this));
        clearInterval(this.interval);
    }

    updateDimensions() {
        //const height = window.innerWidth >= 960 ? window.innerHeight - 160 : 400;
        const height = window.innerHeight - 160;
        this.setState({ height: height });
    }

    refreshData = () => {
        this.getDeviceData();
        this.getAlertData();
    }

    getTime(time) {
        var localDate = new Date();
        var utcDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);
        var now = moment(utcDate);
        var result = null;

        if (moment.duration(now.diff(new moment(time), 'years')) > 1) {
            result = moment.duration(now.diff(new moment(time), 'years')) + " years ago";
            if (result === "1 years ago") result = "1 year ago";
        }
        else if (moment.duration(now.diff(new moment(time), 'hours')) > 24) {
            result = moment.duration(now.diff(new moment(time), 'days')) + " days ago";
            if (result === "1 days ago") result = "1 day ago";
        }
        else if (moment.duration(now.diff(new moment(time), 'minutes')) > 60) {
            result = moment.duration(now.diff(new moment(time), 'hours')) + " hours ago";;
            if (result === "1 hours ago") result = "1 hour ago";
        }
        else if (moment.duration(now.diff(new moment(time), 'seconds')) > 60) {
            result = moment.duration(now.diff(new moment(time), 'minutes')) + " minutes ago";
            if (result === "1 minutes ago") result = "1 minute ago";
        }
        else {
            result = moment.duration(now.diff(new moment(time), 'seconds')) + " seconds ago";
            if (result === "1 seconds ago") result = "1 second ago";
        }

        return result;
    }

    getAlertType(alertCode) {
        if (alertCode === "SOS_Alarm") return "Emergency Button Press";
        if (alertCode === "Fall_Down_Alarm") return "Fall Detected";
        if (alertCode === "Low_Power_Alarm") return "Battery Low";
        if (alertCode === "No_Motion_Alarm") return "No Motion";
        if (alertCode === "Motion_Alarm") return "Motion";
        return alertCode;
    }

    getDeviceData = () => {
        const bearerToken = localStorage.getItem("careXactSOS_BearerToken");

        if (bearerToken !== undefined) {
            const apiURL = localStorage.getItem("careXactSOS_ApiURL");
            const authHeader = "Bearer " + bearerToken;
            const subscriptionKey = localStorage.getItem("careXactSOS_SubscriptionKey");
            const headers = { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': subscriptionKey, 'Authorization': authHeader };

            fetch(apiURL + 'api/Device/AllDevices', { headers })
                .then(response => response.json())
                .then(data => {
                    let filteredDevices = [];

                    if (data !== null)
                        if (data.Devices !== undefined) {
                            filteredDevices = this.applyNullCoordsFilter(data.Devices);
                        }

                    this.setState({ devicesData: filteredDevices });
                })
                .catch(error => {

                });
        }
    }

    getAlertData = () => {
        const bearerToken = localStorage.getItem("careXactSOS_BearerToken");

        if (bearerToken !== undefined) {
            const apiURL = localStorage.getItem("careXactSOS_ApiURL");
            const authHeader = "Bearer " + bearerToken;
            const subscriptionKey = localStorage.getItem("careXactSOS_SubscriptionKey");
            const headers = { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': subscriptionKey, 'Authorization': authHeader };

            fetch(apiURL + 'api/Device/AllAlerts', { headers })
                .then(response => response.json())
                .then(data => {
                    let filteredAlerts = [];
                    if (data !== null)
                        if (data.Alerts !== undefined) {
                            filteredAlerts = this.applyNullAlertCoordsFilter(data.Alerts);
                        }
                    this.setState({ alertData: filteredAlerts });
                })
                .catch(error => {

                });
        }
    }   

    getAlertColor(partyId, alerts) {
        var alertCount = 0;
        var anyHighSeverity = false;
        var anyLowSeverity = false;

        if (alerts.Alerts !== null) {
            var i;
            for (i = 0; i < alerts.Alerts.length; i++) {
                if (alerts.Alerts[i].PartyId === partyId) {
                    if (alerts.Alerts[i].Severity === "Critical") {
                        return "#d32f2f";
                    } else if (alerts.Alerts[i].Severity === "High") {
                        anyHighSeverity = true;
                    } else if (alerts.Alerts[i].Severity === "Low") {
                        anyLowSeverity = true;
                    }
                }
                alertCount++;
            }
        }

        if (anyHighSeverity === true) return "#f57c00";
        if (anyLowSeverity === true) return "#4CB5F5";

        return "#b3b3b3";
    }

    countAlertsForParty(partyId, alerts) {
        var alertCount = 0;

        if (alerts.Alerts !== null) {
            var i;
            for (i = 0; i < alerts.Alerts.length; i++) {
                if (alerts.Alerts[i].PartyId === partyId) {
                    alertCount++;
                }
            }
        }

        return { alertCount } ;
    }

    applyNullCoordsFilter(arr) {
        var result = [];
        var i = 0;
        for (i = 0; i < arr.length; i++) {
            var device = arr[i];
            if (device.DeviceLocation !== null)
                if (device.DeviceLocation !== undefined)
                    if (device.DeviceLocation.Latitude !== null && device.DeviceLocation.Longitude !== null)
                        result.push(device);
        }

        return result;
    }

    applyNullAlertCoordsFilter(arr) {
        if (arr === null) return [];
        var result = [];
        var i = 0;
        for (i = 0; i < arr.length; i++) {
            var alert = arr[i];
            if (alert.Latitude !== null) {
                if (alert.Longitude !== null) {
                    result.push(alert);
                }
            }
        }

        return result;
    }

    getValue(value) {

        if (value === null) return "";
        if (value === undefined) return "";

        return value
    }

    handleShowAlertChange = (e) => {
        this.setState({ showAlerts: e.target.checked });
        localStorage.setItem("CareXactSOS_DeviceLocator_ShowAlerts", e.target.checked);
    }

    handleFindMeClick = (deviceId) => {
        // add this to pop up jsx in render method, <tr><td></td><td><Button size="sm" onClick={e => this.handleLast24hrsClick(device.DeviceId)}>Track Route</Button></td></tr>
        const bearerToken = localStorage.getItem("careXactSOS_BearerToken");

        if (bearerToken !== undefined) {
            this.setState({
                isFindMeButtonDisabled: true
            });

            const apiURL = localStorage.getItem("careXactSOS_ApiURL");
            const authHeader = "Bearer " + bearerToken;
            const subscriptionKey = localStorage.getItem("careXactSOS_SubscriptionKey");
            const headers = { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': subscriptionKey, 'Authorization': authHeader };

            fetch(apiURL + 'api/Device/FindMe?deviceId=' + deviceId, { method: 'POST', headers })
                .then(data => {
                    this.showSuccessAlert("Find Me", "The device will shortly commence an announcement that will replay for approximately 2 minutes.  Once found, press the SOS button on the device to end the announcement.");
                })
                .catch(error => {
                    this.setState({
                        isFindMeButtonDisabled: false
                    });
                });

            setTimeout(() => this.setState({ isFindMeButtonDisabled: false }), 120000);
        }
    }

    handleTestMeClick = (deviceId, phoneNumber) => {
        // <Button disabled={this.state.isTestMeButtonDisabled || device.PayPalSubscriptionActive !== 1} size="sm" color="blueSky" onClick={(e) => this.showConfirmTestMeAlert(e, device.DeviceId)}>Test Me</Button>
        // add this to pop up jsx in render method, <tr><td></td><td><Button size="sm" onClick={e => this.handleLast24hrsClick(device.DeviceId)}>Track Route</Button></td></tr>
        const bearerToken = localStorage.getItem("careXactSOS_BearerToken");

        if (bearerToken !== undefined) {
            this.setState({
                isTestMeButtonDisabled: true
            });

            const apiURL = localStorage.getItem("careXactSOS_ApiURL");
            const authHeader = "Bearer " + bearerToken;
            const subscriptionKey = localStorage.getItem("careXactSOS_SubscriptionKey");
            const headers = { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': subscriptionKey, 'Authorization': authHeader };

            fetch(apiURL + 'api/Device/TestMe?deviceId=' + deviceId, { method: 'POST', headers })
                .then(data => {
                    this.showSuccessAlert("Calling " + phoneNumber, "");
                })
                .catch(error => {
                    this.setState({
                        isTestMeButtonDisabled: false
                    });
                });

            setTimeout(() => this.setState({ isTestMeButtonDisabled: false }), 120000);
        }
    }

    hideAlert = () => {
        this.setState({ alert: null });
    }

    showConfirmTestMeAlert(e, deviceId) {
        e.preventDefault();
        const { classes } = this.props;

        var testPhoneNumber = this.getTestPhoneNumber(deviceId);

        this.setState({
            alert:
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px", width: "400px" }}
                    title=""
                    onConfirm={(e) => this.handleTestMeClick(deviceId, testPhoneNumber)}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={classes.button + " " + classes.success}
                    cancelBtnCssClass={classes.button + " " + classes.danger}
                    confirmBtnText="Send Call Request"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    <div style={{ textAlign: "justify" }}>
                        <ul>
                            <li>Test Me will send a test phone call from the <b>SOS pendant</b> to <b>{testPhoneNumber}</b>.</li>
                            <li>Test Me will use emergency contact phone number 1 from the device configuration.</li>
                        </ul>
                        <p style={{ fontSize: "15px", color: "red" }}><i>Warning : Test Me usage is covered under the CareXact SOS Fair Use Policy.</i></p>
                    </div>
                </SweetAlert>
        });
    }

    showSuccessAlert(title, message) {
        const { classes } = this.props;

        this.setState({
            alert:
                <SweetAlert
                    success
                    style={{ display: "block", marginTop: "-100px", width: "400px" }}
                    title={title}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    confirmBtnCssClass={
                        classes.button + " " + classes.success
                    }>
                    <div>
                        {message}
                    </div>
                </SweetAlert>
        });
    }

    showErrorAlert(title, message) {
        const { classes } = this.props;

        this.setState({
            alert:
                <SweetAlert
                    error
                    style={{ display: "block", marginTop: "-100px", width: "400px" }}
                    title={title}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    confirmBtnCssClass={
                        classes.button + " " + classes.success
                    }>
                    <div>
                        {message}
                    </div>
                </SweetAlert>
        });
    }

    showInfo(classes) {
        this.setState({
            alert:
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px", width: "400px" }}
                    title={""}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    confirmBtnCssClass={
                        classes.button + " " + classes.success
                    }>
                    <div style={{ textAlign: "justify" }}>
                        <table>
                            <th width="10%"></th><th width="90%"></th>
                            <tr>
                                <td><img src={alertMarkerIcon} height="50px" width="130px" objectFit="contain" /></td><td>Red map markers show where an alert happened.  Click on the red map marker to view details about the alert.</td>
                            </tr>
                            <tr>
                                <td>
                                    <img src={deviceMarkerIcon} height="50px" width="130px" objectFit="contain" /></td><td>Blue map markers represent the last known position of the SOS pendant.</td>
                            </tr>
                        </table>
                        <ul>
                            <li>Click on a blue map marker to view the contact details for the person wearing the SOS pendant.</li>
                            <li>To find a lost pendant, click a blue map marker then click the FIND ME button to make the SOS pendant sound an audible message.</li>
                            <li>To send a test call from an SOS pendant to emergency contact phone number 1 without triggering an alert, click a blue map marker then click the TEST ME button.</li>
                        </ul>
                    </div>
                </SweetAlert>
        });
    }

    getTestPhoneNumber = (deviceId) => {
        var devices = this.state.devicesData
        var testPhoneNumber;

        devices.forEach(device => {
            if (device.DeviceId === deviceId) {
                device.AuthorizedNumbers.forEach(slot => {
                    if (slot.Serial === 0)
                        testPhoneNumber = slot.AuthorizedNumber;
                });
            }
        });

        return testPhoneNumber;
    }

    onZoomEnd = (e) => {
        this.setState({ zoom: e.target._zoom });
    }

    async reverseGeoCode(lat, lng){
        await fetch('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&key=AIzaSyAXZhUxTmtSKcmO77MbSj57H8EX7_-yDlQ')
            .then(response => response.json())
            .then(data => {
                if (data.status !== "OK") {
                    this.setState({
                        currentSelectedDeviceAddress: ""
                    });
                } else {
                    var deviceAddress = data.results[0].formatted_address.replaceAll(',', '\n');
                    deviceAddress = deviceAddress.replaceAll(' \n', '\n');
                    deviceAddress = deviceAddress.replaceAll('\n ', '\n');

                    this.setState({
                        currentSelectedDeviceAddress: deviceAddress
                    });
                }
            })
            .catch(error => {
                console.out(error);
            });
    }

    isLatLongDeviceCollision = (deviceId, lat, lng) => {
        var collisionFound = false;
        const devices = this.state.devicesData;

        devices.forEach(device => {
            if (device.DeviceId !== deviceId) {
                var eachDeviceLat = parseFloat(device.DeviceLocation.Latitude);
                var eachDeviceLng = parseFloat(device.DeviceLocation.Longitude);

                var latDifference = Math.abs(eachDeviceLat - lat);
                var lngDifference = Math.abs(eachDeviceLng - lng);

                if ((latDifference < 0.0002) && (lngDifference < 0.0002)) 
                    collisionFound = true;

                //if ((lat === device.DeviceLocation.Latitude) && (lng === device.DeviceLocation.Longitude)) {
                //}
            }
        });

        return collisionFound;
    }

    render() {
        const { classes } = this.props;

        var filteredDevices = this.state.devicesData !== null ? this.state.devicesData : null;
        if (filteredDevices === null) { return (loader); }

        var filteredAlerts = this.state.alertData !== null ? this.state.alertData : null;
        if (filteredAlerts === null) { return (loader); }

        var bumpLat = 0.0001;
        var bumpLng = 0.0001;

        const bounds = latLngBounds();
        if (filteredDevices.length > 0)
            filteredDevices.forEach(device => {
                if (this.isLatLongDeviceCollision(device.DeviceId, parseFloat(device.DeviceLocation.Latitude), parseFloat(device.DeviceLocation.Longitude))) {
                    device.DeviceLocation.Latitude = parseFloat(device.DeviceLocation.Latitude) + bumpLat;
                    device.DeviceLocation.Longitude = parseFloat(device.DeviceLocation.Longitude) + bumpLng;
                    bumpLat = bumpLat + 0.0001;
                    bumpLng = bumpLng + 0.0001;
                }

                bounds.extend([device.DeviceLocation.Latitude, device.DeviceLocation.Longitude]);
            });

        if (this.state.showAlerts)
            if (filteredAlerts.length > 0)
                filteredAlerts.forEach(alert => { bounds.extend([alert.Latitude, alert.Longitude]) });

        return (
            filteredDevices && filteredDevices.length > 0 ?
                <GridContainer>
                    <Fab size="small" color="white" aria-label="add" style={infoButtonStyle}>
                        <img src={info} height="45" width="45" onClick={event => this.showInfo(classes)} />
                    </Fab>
                    {this.state.alert}
                    <GridItem xs={12}>
                        <div>
                            <div style={{ position: "relative" }} >
                                <div style={{ position: "relative", left: "5px", top: "25px", zIndex: "10", width: "180px", borderRadius: "15px", padding: "5px", color: "#FFFFFF", background: "rgba(255,255,255,0.8)", width:"150px" }}>
                                    <FormControlLabel control={<Switch checked={this.state.showAlerts} onChange={e => this.handleShowAlertChange(e)} value="showAlertsEnabled" classes={{ switchBase: classes.switchBase, checked: classes.switchChecked, thumb: classes.switchIcon, track: classes.switchBar }} />} classes={{ label: classes.label }} label="Show Alerts" />
                                </div>
                            </div>
                            <div style={{ position: "relative", top: "-30px", zIndex: 1 }} >
                                <Map tap={false} zoom={this.state.zoom} style={{ height: this.state.height, width: '100%' }} key='resetMe' bounds={bounds} zoomControl={false}>
                                    <ZoomControl position="bottomright"/>
                                    <TileLayer attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                                    {filteredDevices !== null && filteredDevices.map(device => ( 
                                        <Marker key={device.Party.PartyId} icon={deviceMarker} position={[device.DeviceLocation.Latitude, device.DeviceLocation.Longitude]} zIndexOffset={100} onClick={() => { this.reverseGeoCode(device.DeviceLocation.Latitude, device.DeviceLocation.Longitude) }}>
                                            <Popup>
                                                <table style={{ verticalAlign: 'topLeft', width: '250px'}}>
                                                    <tr><td style={{ verticalAlign: 'top' }}>Device Name</td><td style={{ verticalAlign: 'top' }}>{this.getValue(device.DeviceName)}</td></tr>
                                                    <tr><td style={{ verticalAlign: 'top' }}>Name</td><td style={{ verticalAlign: 'top' }}>{this.getValue(device.Party.FirstName)} {this.getValue(device.Party.LastName)}</td></tr>
                                                    <tr><td style={{ verticalAlign: 'top' }}>Phone Number</td><td style={{ verticalAlign: 'top' }}>{this.getValue(device.Party.PhoneNumber)}</td></tr>
                                                    <tr><td style={{ verticalAlign: 'top' }}>Address</td><td style={{ verticalAlign: 'top' }}><div style={{whiteSpace: 'pre-wrap'}}>{this.state.currentSelectedDeviceAddress}</div></td></tr>
                                                </table>
                                                <Button disabled={this.state.isFindMeButtonDisabled || device.PayPalSubscriptionActive !== 1} size="sm" color="blueSky" onClick={() => this.handleFindMeClick(device.DeviceId)}>Find Me</Button>
                                            </Popup>
                                        </Marker>
                                    ))}
                                    {
                                        this.state.showAlerts &&
                                            filteredAlerts !== null && filteredAlerts.map(alert => (
                                            <Marker key={alert.AlertId} icon={alertMarker} position={[alert.Latitude, alert.Longitude]} onClick={() => { }}>
                                                <Popup>
                                                    <table>
                                                        <tr><td>Alert Title</td><td>{this.getAlertType(alert.AlertCode)}</td></tr>
                                                        <tr><td>Name</td><td>{this.getValue(alert.Name)}</td></tr>
                                                        <tr><td>Phone Number</td><td>{this.getValue(alert.PhoneNumber)}</td></tr>
                                                        <tr><td>Time</td><td>{this.getTime(alert.AlertDateTime)}</td></tr>
                                                    </table>
                                                </Popup>
                                            </Marker>
                                    ))}
                                    </Map>
                             </div>
                        </div>
                    </GridItem>
                </GridContainer>
                :
                <GridContainer>
                    <Fab size="small" color="white" aria-label="add" style={infoButtonStyle}>
                        <img src={info} height="45" width="45" onClick={event => this.showInfo(classes)} />
                    </Fab>
                    <GridItem xs={12}>
                        <div>
                            <div style={{ position: "relative" }} >
                                <div style={{ position: "relative", left: "5px", top: "25px", zIndex: "10", width: "180px", borderRadius: "15px", padding: "5px", color: "#FFFFFF", background: "rgba(255,255,255,0.8)", width: "150px" }}>
                                    <FormControlLabel control={<Switch checked={this.state.showAlerts} onChange={e => this.handleShowAlertChange(e)} value="showAlertsEnabled" classes={{ switchBase: classes.switchBase, checked: classes.switchChecked, thumb: classes.switchIcon, track: classes.switchBar }} />} classes={{ label: classes.label }} label="Show Alerts" />
                                </div>
                            </div>
                            <div style={{ position: "relative", top: "-30px", zIndex: 1 }} >
                            <Map 
                                tap={false}
                                style={{ height: this.state.height, width: '100%' }}
                                key='resetMe'
                                center={position}
                                zoom={defaultConfig.zoom}
                                boundsOptions={{ padding: [150, 150] }}
                                zoomControl={false}>
                                <TileLayer attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                                <ZoomControl position="bottomright" />
                                    </Map>
                            </div>
                        </div>
                    </GridItem>
                </GridContainer>
        );
    }
}

DeviceLocator.propTypes = {
    classes: PropTypes.object
};

export default withStyles(style)(DeviceLocator);

