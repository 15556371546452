import React from "react";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

// core components
import GridContainer from "../../../../components/Grid/GridContainer.js";
import GridItem from "../../../../components/Grid/GridItem.js";
import CustomInput from "../../../../components/CustomInput/CustomInput.js";

import validationFormStyles from "../../../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import alertStyles from "../../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import info from "../../../../assets/img/info.png";
import Fab from '@material-ui/core/Fab';
const infoButtonStyle = {
    margin: 0,
    top: '550',
    right: 60,
    bottom: 'auto',
    left: 'auto',
    position: 'fixed'
};

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
    },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  },
    ...validationFormStyles,
    ...alertStyles
};

// preload trick - load the images used by sweet alert ahead of time.
const info_image = new Image().src = "https://carexactsosprod.blob.core.windows.net/help/productCode.jpg";

class ProductCodeStep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ProductCode: localStorage.getItem("careXactSOS_ProductCode"),
            ProductCodeRequiredState: "",
            DeviceName: "",
            alert: null
        };
    }

    componentDidMount() {
        if (this.state.ProductCode !== null)
            if (this.state.ProductCode !== undefined)
                if (this.state.ProductCode.length >= 8)
                    this.setState({ ProductCodeRequiredState: "success" })
    }

    sendState() {
        return this.state;
    }

    verifyLength(value, length) {
        if (value.length >= length) {
          return true;
        }
        return false;
    }

    hideAlert = () => {
        this.setState({ alert: null });
    }

    showInfo(classes) {
        this.setState({
            alert:
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px", width: "500px" }}
                    title={""}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    confirmBtnCssClass={
                        classes.button + " " + classes.success
                    }>
                    <div style={{ textAlign: "justify" }}>
                        <center><img src={`${info_image}?${global.Date.now()}`} height="70%" width="70%" objectFit="contain" /></center><br />
                        <p>Open the Quick Start guide enclosed in your SOS pendant box and go to Step 1 to find the unique product code for your SOS pendant.</p> 
                        <p>The example above shows a product code of <i>1X8090C9</i>.  <i>1X8090C9</i> is only an example and not to be used to add your device.</p>
                        <p>Enter the product code from the Quick Start guide enclosed in your SOS pendant box.</p>
                    </div>
                </SweetAlert>
        });
    }

    change(event, stateName, type, stateNameEqualTo) {
        switch (type) {
          case "length":
            if (this.verifyLength(event.target.value, stateNameEqualTo)) {
              this.setState({ [stateName + "RequiredState"]: "success" });
            } else {
              this.setState({ [stateName + "RequiredState"]: "error" });
            }
            break;
          default:
            break;
        }
        this.setState({ [stateName]: event.target.value });
  }

    isValidated() {
        if (this.state.ProductCodeRequiredState === "success") {
          return true;
        } else {
            if (this.state.ProductCodeRequiredState !== "success") {
                this.setState({ ProductCodeRequiredState: "error" });
            }
        }
        return false;
    }

    render() {
        const { classes } = this.props;        

        return (
            <div>
                <Fab size="small" color="white" aria-label="add" style={infoButtonStyle}>
                    <img src={info} height="45" width="45" onClick={event => this.showInfo(classes)} />
                </Fab>
                {this.state.alert}
                 <GridContainer justify="center">
                <GridItem xs={8} sm={8}>
                    <h4 className={classes.infoText}>Please enter your 8 character product code and name your device.</h4>
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <CustomInput labelText="Product Code" error={this.state.ProductCodeRequiredState === "error"} id="ProductCode" formControlProps={{ fullWidth: true }}
                        inputProps={{
                            onChange: event => this.change(event, "ProductCode", "length", 8),
                            type: "text",
                            value: this.state.ProductCode,
                            autoComplete: "off",
                            endAdornment: this.state.ProductCodeRequiredState === "error" ? (<InputAdornment position="end"><Close className={classes.danger} /></InputAdornment>) : (undefined)
                        }} />
                    <CustomInput labelText="Device Name" id="DeviceName" formControlProps={{ fullWidth: true }}
                        inputProps={{
                            onChange: event => this.setState({ DeviceName: event.target.value }),
                            type: "text",
                            maxLength: 20,
                            value: this.state.DeviceName,
                            autoComplete: "off"
                        }} />
                </GridItem>
                </GridContainer>
            </div>
        );
  }
}

ProductCodeStep.propTypes = {
    classes: PropTypes.object
};

export default withStyles(style, alertStyles)(ProductCodeStep);
