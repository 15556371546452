import React from "react";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// core components
import GridContainer from "../../../../components/Grid/GridContainer.js";
import GridItem from "../../../../components/Grid/GridItem.js";
import CustomInput from "../../../../components/CustomInput/CustomInput.js";

import validationFormStyles from "../../../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import alertStyles from "../../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import info from "../../../../assets/img/info.png";
import Fab from '@material-ui/core/Fab';

const infoButtonStyle = {
    margin: 0,
    top: '550',
    right: 60,
    bottom: 'auto',
    left: 'auto',
    position: 'fixed'
};

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
    },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  },
    ...validationFormStyles,
    ...alertStyles
};

// preload trick - load the images used by sweet alert ahead of time.
const info_image = new Image().src = "https://carexactsosprod.blob.core.windows.net/help/productCode.jpg";

class TermsStep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            TermsAcceptedState: false,
            scrolledToBottom: false,
            alert: null
        };
    }

    componentDidMount() {
        
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    sendState() {
        return this.state;
    }

    verifyLength(value, length) {
        if (value.length >= length) {
          return true;
        }
        return false;
    }

    hideAlert = () => {
        this.setState({ TermsAcceptedState: false, alert: null });
    }

    AcceptTerms = () => {
        this.setState({ TermsAcceptedState: true, alert: null });
    }

    showConfirmTerms() {       
        const { classes } = this.props; 

        this.setState({
            alert:
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px", width: "400px" }}
                    title="Terms and Conditions"
                    onConfirm={() => this.AcceptTerms()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={classes.button + " " + classes.success}
                    cancelBtnCssClass={classes.button + " " + classes.danger}
                    confirmBtnText="Accept"
                    cancelBtnText="Decline"
                    showCancel
                >
                </SweetAlert>
        });
    }

    showMustScrollInfo() {
        const { classes } = this.props;

        this.setState({
            alert:
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px", width: "400px" }}
                    onConfirm={() => this.hideAlert()}
                    confirmBtnCssClass={classes.button + " " + classes.success}
                    cancelBtnCssClass={classes.button + " " + classes.danger}
                    confirmBtnText="Ok"
                >
                    <div>
                        <p>Scroll to the bottom of the terms and conditions.</p>
                        <p>After reading the terms and conditions tick <i>"I agree to the terms and conditions"</i> before proceeding.</p>
                    </div>
                </SweetAlert>
        });
    }

    showMustAcceptInfo() {
        const { classes } = this.props;

        this.setState({
            alert:
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px", width: "400px" }}
                    onConfirm={() => this.hideAlert()}
                    confirmBtnCssClass={classes.button + " " + classes.success}
                    cancelBtnCssClass={classes.button + " " + classes.danger}
                    confirmBtnText="Ok"
                >
                    <div>
                        <p>Tick <i>"I agree to the terms and conditions"</i> before proceeding.</p>
                    </div>
                </SweetAlert>
        });
    }

    isValidated() {
        if (this.state.TermsAcceptedState === true) {
            return true;
        } else {
            if (this.state.scrolledToBottom === false)
                this.showMustScrollInfo();
            else
                this.showMustAcceptInfo();
        }
        return false;
    }

    paneDidMount = (node) => {
        if (node) {
            node.addEventListener("scroll", this.handleScroll.bind(this));
        }
    }

    handleToggleAcceptTerms = () => {
        if (this.state.TermsAcceptedState === false) this.setState({ TermsAcceptedState: true });
        if (this.state.TermsAcceptedState === true) this.setState({ TermsAcceptedState: false });
    };

    handleScroll = (event) => {
        var node = event.target;
        const bottom = node.scrollHeight - node.scrollTop === node.clientHeight;
        if (bottom) {
            this.setState({ scrolledToBottom: true });
        }
    }

    render() {
        const { classes } = this.props;        

        return (
            <div>
                <div ref={this.paneDidMount} style={{ overflowY: 'scroll', maxHeight: '300px', justifyContent: 'justify' }}>
                    <p style={{ color: "#4A4C9A" }}><strong>Please read the following terms and conditions carefully as they govern your use of Tunstall Gem4 SOS Personal Alert Pendant</strong></p>
                    <ol>
                        <li>
                            <strong>Definitions</strong><br />
                            In these Terms and Conditions the following definitions shall apply:<br /><br />
                            <ol type="a">
                                <li><strong>"CareXact" means the provider of the software portal that supports the Gem4 SOS service provided by Tunstall.</strong></li>
                                <li><strong>"Commencement Date"</strong> is the date on which the Customer has purchased the Device and agreed to the terms via the Tunstall website.</li>
                                <li><strong>"Customer"</strong> is the person who will be using the Device or the person who is purchasing the Device or paying for the service.</li>
                                <li><strong>"Device"</strong> is the Tunstall Healthcare GEM4 SOS Pendant</li>
                                <li><strong>"Installation"</strong> shall mean the installation of the Device by the Customer in accordance with the User Guide accompanying the Device, when delivered to the Customer.</li>
                                <li><strong>"Maintenance"</strong> shall mean the Maintenance of the Device required to be carried out by the Customer in accordance with the User Guide.</li>
                                <li><strong>"Nominated Responder"</strong> shall mean the person or persons the Customer has nominated to be notified when the Customer uses the Device for its intended purpose.</li>
                                <li><strong>"Tunstall"</strong> shall mean Tunstall Australasia Pty Ltd (ACN 059 121 863) whose registered office is situated at  Unit 1,  56 Lavarack Avenue, Eagle Farm QLD 4009 and any related Company.</li>
                                <li><strong>"SIM"</strong> A SIM card is a small card that contains a mobile network subscriber's account information. This allows the Device using the card to attach to a mobile network.</li>
                                <li><strong>"Subscription Fee"</strong> means a monthly fee paid by the Customer, which covers the cost of the SIM card and fee for the monthly portal service, in order for the Device to notify Nominated Responders as necessary.</li>
                                <li><strong>"Subscription Services"</strong> means the SIM card and use of the monthly portal service, in order for the Device to notify Nominated Responders as necessary.</li>
                                <li><strong>"User Guide"</strong> shall mean the documentation provided with the Device, outlining the installation, maintenance and care for which the Customer is responsible for.</li>
                            </ol>
                        </li><br />
                        <li>
                            <strong>Device Disclosure Statement</strong>
                            <ol type="a">
                                <li>The purchase and continued use of the Device constitutes the acknowledgement and acceptance of these terms and conditions by the Customer, including any changes to the terms and conditions as updated from time to time and made available on Tunstall's website.</li>
                                <li>The Device available for purchase from Tunstall is to be self-installed and maintained by the Customer and the on-going maintenance and testing is solely the Customer's responsibility.</li>
                                <li>For continuous operation the Device requires an active SIM account and batteries to be charged or replaced from time to time.</li>
                                <li>While the Device available and supplied by Tunstall will provide independence and assist in the supervised care of the Customer by their Nominated Responder/s, the Device shall not be considered as a life saving device. Tunstall accepts no liability for the loss of property or personal injury, loss of income or any other liability that should result from the Device failing to operate.</li>
                            </ol>
                        </li><br />
                        <li>
                            <strong>Mobile network coverage & GPS signal</strong>
                            <ol type="a">
                                <li>The reliability of the Device is dependent on the available 4G or 3G mobile network coverage in your location.</li>
                                <li>The accuracy of the GPS signal is determined by the availability of unobstructed line of sight to available satellites and can be blocked by underground carparks, lifts, concrete buildings, tunnels and other constructions.</li>
                                <li>The availability of mobile network coverage and accuracy of the GPS signal is reliant on third party providers and is not guaranteed by Tunstall.</li>
                                <li>Tunstall does not guarantee that the Device can connect to the mobile network at all times, or that a help text message or help call will be received by every nominated responder at all times or within a short time frame.</li>
                            </ol>
                        </li><br />
                        <li>
                            <strong>Price</strong>
                            <ol type="a">
                                <li>All prices quoted on the website are in Australian dollars.</li>
                                <li>Tunstall reserves the right to increase the price of the Customer's subscription from time to time and the Customer will be given reasonable notice prior to any such price.</li>
                                <li>All prices payable, as advertised, are inclusive of GST. GST will be added at time of payment.</li>
                            </ol>
                        </li><br />
                        <li>
                            <strong>Fair Use</strong><br />
                            The intention of this Clause is to establish when the Device, installed with the Telstra managed SIM card, can be used by a Customer as follows:<br /><br />
                            <ol type="a">
                                <li>A Customer may use the SOS and side-talk buttons on the Device in emergency situations.</li>
                                <li>They may also use the Device when testing the Device initially, and in suitable intervals (3 months), where testing is reasonably required e.g. one press of SOS button to test nominated responders' mobile numbers are correct.</li>
                                <li>The Customer may not use the SIM service in a way which is improper or unacceptable.</li>
                            </ol>
                        </li><br />
                        <li>
                            <strong>Improper Use</strong>
                            <ol type="a">
                                <li>Tunstall considers the Customer's use of the service improper if the Customer uses it in a way which is other than Tunstall intended it for.</li>
                                <li>A Customer shall not use the Device like a traditional mobile communication device.</li>
                            </ol>
                        </li><br />
                        <li>
                            <strong>SIM Supply</strong>
                            <ol type="a">
                                <li>Tunstall is responsible for the supply of the SIM card installed in the Device.</li>
                                <li>The Tunstall supplied SIM remains the property of Tunstall at all times.</li>
                                <li>The Customer is not permitted to remove or tamper with the SIM at any time.</li>
                                <li>Tunstall will be entitled to charge the Customer for misuse or excessive use of a Tunstall supplied SIM.</li>
                            </ol>
                        </li><br />
                        <li>
                            <strong>Excessive Use</strong>
                            <ol type="a">
                                <li>SMS and Voice usage for each active Device is monitored by Tunstall.</li>
                                <li>If Tunstall deems the usage patterns to be excessive, i.e. used significantly more than the Device is typically used other than for emergency situations, the Customer will be liable for further charges for excess operation (see improper use).</li>
                                <li>Where excessive usage continues and/or a Customer fails to reimburse Tunstall for excess usage, the Customer needs to be aware that the Tunstall provided SIM installed in the Device can be subject to cancellation by Tunstall.</li>
                            </ol>
                        </li><br />
                        <li>
                            <strong>Limitation of Liability</strong>
                            <ol type="a">
                                <li>Tunstall is not liable for any injury, loss or damage caused by:</li><br />
                                <ol type="i">
                                    <li>the failure of the Customer's personal nominated responders to respond to; or respond promptly to a call for help via the Device; or</li>
                                    <li>failure of the Customer to operate and maintain the Device properly in accordance with the User Guide, including to regularly test; or</li>
                                    <li>the Device failing to operate due to mobile network connectivity issues.</li>
                                </ol><br />
                                <li>The Customer agrees to indemnify Tunstall against all claims for injury, loss or damage arising out of the above circumstances.</li>
                            </ol>
                        </li><br />
                        <li>
                            <strong>Warranty</strong>
                            <ol type="a">
                                <li>A twelve (12) month warranty period is offered except where otherwise stated.</li>
                                <li>This warranty policy accommodates defects in materials and workmanship.</li>
                                <li>In the event of a warranty claim, proof of purchase must be provided including a detailed description of the perceived fault.</li>
                                <li>Where a fault is confirmed by Tunstall's technical department the faulty product will, at Tunstall's discretion be repaired or replaced.</li>
                                <li>Freight charges for a Device where a fault is confirmed by Tunstall's technical department, will be the responsibility of Tunstall.</li>
                                <li>Warranty is subject to the Device being maintained and used in accordance with the User Manual.</li>
                                <li>Warranty is void if the Device has been tampered with by the Customer, or any other person's, in any manner.</li>
                            </ol>
                        </li><br />
                        <li>
                            <strong>Privacy</strong>
                            <ol type="a">
                                <li>Tunstall assures confidential personal information will only be used for the purpose for which the Customer has provided it.</li>
                                <li>By agreeing to these Terms and Conditions, the Customer acknowledges and accepts that their personal information will be shared with CareXact only for the sole purposes of providing the service and the Device as intended.</li>
                                <li>Notwithstanding clause 12(b), confidential personal information will not be provided to any other person or agency without the Customer's consent or that of their legally designated representative.</li>
                                <li>For any further queries the Tunstall Privacy Officer can be contacted at <a href="mailto:APAC.privacy@tunstall.com">APAC.privacy@tunstall.com</a>.</li>
                            </ol>
                        </li><br />
                        <li>
                            <strong>Basis of Subscription Services</strong>
                            <ol type="a">
                                <li>The Subscription Service is provided to the Customer on a "rolling monthly" basis and the Customer will pay a monthly recurring fee in advance for any Subscription Services which they have subscribed to.</li>
                                <li>The Subscription Services will be provided to the Customer during the period of time that the Customer has paid for the relevant Subscription Services.</li>
                                <li> If the monthly Subscription Fee remains unpaid 21 days after the renewal due date, due to any circumstance including declined payments, the Subscription Services may be deactivated without further notice to the Customer.</li>
                                <li>In this case the Device will no longer operate and an additional fee may be charged to reactivate the Subscription Services.</li>
                                <li>If the Customer wants to cancel the Subscription Services, this can be done within the CareXact portal and any cancellation will take effect at the end of the Customer's current monthly billing cycle.</li>
                                <li>When the cancellation takes effect, the Customer will be unable to continue to use the Device as it is intended to be used, and the cancelled Subscription Services will no longer be available.</li>
                            </ol>
                        </li><br />
                        <li>
                            <strong>Consumer Guarantee</strong><br /><br />
                            Nothing in these Terms and Conditions shall be read or applied so as to exclude, restrict or modify or have the effect of excluding, restricting or modifying any condition, warranty, guarantee, right or remedy implied by law (including the Australian Consumer Law) which cannot by law be excluded, restricted or modified.
                        </li><br />
                        <li>
                            <strong>Change of Mind Returns</strong><br /><br />
                            Unless otherwise agreed by Tunstall in its discretion, unfortunately Tunstall are unable to offer change of mind returns and exchanges/refunds. This no change of mind policy for returns does not limit or apply to any returns made for defective products in accordance with Tunstall's warranty clause.
                        </li><br />
                        <li>
                            <strong>General</strong>
                            <ol type="a">
                                <li>These Terms and Conditions are agreed by the Customer and the Customer shall not at any time assign, pledge, mortgage, transfer or otherwise dispose in whole or in part of any or all the rights under these Terms and Conditions.</li>
                                <li>Tunstall may at any time assign, pledge, mortgage, transfer or otherwise dispose in whole or in part of any or all the rights under these Terms and Conditions.</li>
                                <li>Any time or other indulgence granted by Tunstall to the Customer shall not affect the strict rights of Tunstall under these Terms and Conditions.</li>
                            </ol>
                        </li><br />
                    </ol>
                    <FormControlLabel
                        classes={{
                            root: classes.checkboxLabelControl,
                            label: classes.checkboxLabel
                        }}
                        control={
                            <Checkbox
                                tabIndex={-1}
                                onClick={() => this.handleToggleAcceptTerms()}
                                checked={this.state.TermsAcceptedState}
                                checkedIcon={
                                    <Check className={classes.checkedIcon} />
                                }
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot
                                }}
                            />
                        }
                        label={
                            <span>
                                I agree to the terms and conditions
                            </span>
                        }
                    />
                </div>
                {this.state.alert}
                <GridContainer justify="center">
                    <GridItem xs={12} sm={6}>
                        
                    </GridItem>
                </GridContainer>
            </div>
        );
  }
}

TermsStep.propTypes = {
    classes: PropTypes.object
};

export default withStyles(style, alertStyles)(TermsStep);
