import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

// core components
import Wizard from "../../../components/Wizard/Wizard.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";

import DeviceSettingsVisionStep from "./DeviceDetailsWizardSteps/DeviceSettingsVision.js";
import ContactDetailsStep from "./DeviceDetailsWizardSteps/ContactDetails.js";
import ProductCodeStep from "./DeviceDetailsWizardSteps/ProductCode.js";
import TermsStep from "./DeviceDetailsWizardSteps/Terms.js";

import { makeStyles } from "@material-ui/core/styles";
import alertStyles from "../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useAlertStyles = makeStyles(alertStyles);

export default function WizardAddDeviceVision(props) {
    const alertClasses = useAlertStyles();
    const [Saving, setSaving] = React.useState(false);
    const [alert, setAlert] = React.useState(null);

    const hideAlert = () => {
        setAlert(null);
    };

    const showAlert = (title, message) => {
        setAlert(
            <SweetAlert
                error
                style={{ display: "block", marginTop: "-100px", width: "400px" }}
                title={title}
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}>
                    { message }
            </SweetAlert>
        );
    };

    function getValue(value) {
        if (value === undefined) return "";

        return value;
    }

    const handleFinish = async (event) => {
        if (Saving !== true) {
            var deviceSettingsStep = event["deviceSettings"];
            var contactDetailsStep = event["contactDetails"];
            var productCodeStep = event["productCode"];
            var responseOk = true;

            const json = {
                DeviceId: 0,
                DeviceName: productCodeStep !== undefined ? getValue(productCodeStep.DeviceName) : "",
                ProductCode: productCodeStep !== undefined ? getValue(productCodeStep.ProductCode.toUpperCase().replace("O","0")) : "",
                Party: {
                    PartyId: 0,
                    FirstName: contactDetailsStep !== undefined ? getValue(contactDetailsStep.FirstName) : "",
                    LastName: contactDetailsStep !== undefined ? getValue(contactDetailsStep.LastName) : "",
                    PhoneNumber: contactDetailsStep !== undefined ? getValue(contactDetailsStep.PhoneNumber) : "",
                    StreetNumber: contactDetailsStep !== undefined ? getValue(contactDetailsStep.StreetNumber) : "",
                    StreetName: contactDetailsStep !== undefined ? getValue(contactDetailsStep.StreetName) : "",
                    StreetType: contactDetailsStep !== undefined ? getValue(contactDetailsStep.StreetType) : "",
                    Suburb: contactDetailsStep !== undefined ? getValue(contactDetailsStep.Suburb) : "",
                    State: contactDetailsStep !== undefined ? getValue(contactDetailsStep.State) : "",
                    PostCode: contactDetailsStep !== undefined ? getValue(contactDetailsStep.PostCode) : "",
                    Country: contactDetailsStep !== undefined ? getValue(contactDetailsStep.Country) : "",
                    AddressLatitude: contactDetailsStep !== undefined ? getValue(contactDetailsStep.AddressLatitude) : "",
                    AddressLongitude: contactDetailsStep !== undefined ? getValue(contactDetailsStep.AddressLongitude) : "",
                    EntityUpdated: true
                },
                DeviceSettings: [
                    {
                        SettingKey: "PrivacyLevel",
                        SettingValue: deviceSettingsStep !== undefined ? getValue(deviceSettingsStep.privacyLevel) : "medium"
                    },
                    {
                        SettingKey: "EdgeDetectionThreshold",
                        SettingValue: deviceSettingsStep !== undefined ? getValue(deviceSettingsStep.edgeDetectionThreshold) : "200"
                    },
                    {
                        SettingKey: "ObjectPolygonsEnabled",
                        SettingValue: deviceSettingsStep !== undefined ? (deviceSettingsStep.objectPolygonsEnabled ? "1" : "0") : "1"
                    },
                    {
                        SettingKey: "MotionSensitivity",
                        SettingValue: deviceSettingsStep !== undefined ? deviceSettingsStep.motionSensitivity : "medium"
                    }
                ]
            };

            const apiURL = localStorage.getItem("careXactSOS_ApiURL");
            const authHeader = "Bearer " + localStorage.getItem("careXactSOS_BearerToken");
            const subscriptionKey = localStorage.getItem("careXactSOS_SubscriptionKey");
            const headers = { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': subscriptionKey, 'Authorization': authHeader };

            setSaving(true);

            await fetch(apiURL + 'api/Device/Vision', {
                method: 'POST',
                body: JSON.stringify(json),
                headers: headers
            })
                .then(function (response) {
                    responseOk = response.ok;
                    return response.json();
                })
                .then((response) => {
                    if (responseOk) {
                        localStorage.setItem("careXactSOS_DeviceId", response);
                        props.closeFormLaunchPayment();
                    } else {
                        throw Error(response.Message);
                    }
                })
                .catch(error => {
                    setSaving(false);
                    showAlert("", "Check the product code is valid and try again or contact support. " + error);
                    console.log(error.message);
                }
            );
        }
    };

    const handleClose = async (event) => {
        props.closeFormNoRefresh();
    }

    return (
        <GridContainer justify="center">
            <GridItem xs={12} sm={12}>
                <Wizard
                    color="blueSky"
                    validate
                    steps={[
                        { stepName: "Terms", stepComponent: TermsStep, stepId: "terms" },
                        { stepName: "Product Code", stepComponent: ProductCodeStep, stepId: "productCode" },
                        { stepName: "Device Settings", stepComponent: DeviceSettingsVisionStep, stepId: "deviceSettings" },
                        { stepName: "Contact Details", stepComponent: ContactDetailsStep, stepId: "contactDetails" }
                    ]}
                    title=""
                    subtitle=""
                    finishButtonClick={event => handleFinish(event)}
                    closeButtonClick={event => handleClose(event)}
                />
                { alert }
            </GridItem>
        </GridContainer>
    );
}
