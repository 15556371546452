import React, { Component } from "react";
import moment from 'moment';

// material-ui icons
import { withStyles } from '@material-ui/core/styles';

// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import Button from "../../../components/CustomButtons/Button.js";
import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";

import regularFormStyles from "../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle.js";
import alertStyles from "../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import Loader from 'react-loader-spinner';
const loaderStyle = { position: "fixed", top: "50%", left: "55%", transform: "translate(-50%, -50%)"}
const loader = (<div style={loaderStyle}> <Loader type="ThreeDots" color="#4cb5f5" width="200" height="200" /></div>);

const style = {
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center"
    },
    inputAdornmentIcon: {
        color: "#555"
    },
    choiche: {
        textAlign: "center",
        cursor: "pointer",
        marginTop: "20px"
    },
    ...regularFormStyles,
    ...alertStyles
};

class ComputerVisionLog extends Component {
    interval = null;
   
    constructor(props) {
        super(props);

        this.state = {
            computerVisionLogData: null,
            isLoading : true
        };
    }

    componentDidMount() {
        this.interval = setInterval(this.refreshData, 300000);
        this.getComputerVisionLogData();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getTime(time) {
        var localDate = new Date();
        var utcDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);
        var now = moment(utcDate);
        var result = null;

        if (moment.duration(now.diff(new moment(time), 'years')) > 1) {
            result = moment.duration(now.diff(new moment(time), 'years')) + " years ago";
            if (result === "1 years ago") result = "1 year ago";
        }
        else if (moment.duration(now.diff(new moment(time), 'hours')) > 24) {
            result = moment.duration(now.diff(new moment(time), 'days')) + " days ago";
            if (result === "1 days ago") result = "1 day ago";
        }
        else if (moment.duration(now.diff(new moment(time), 'minutes')) > 60) {
            result = moment.duration(now.diff(new moment(time), 'hours')) + " hours ago";;
            if (result === "1 hours ago") result = "1 hour ago";
        }
        else if (moment.duration(now.diff(new moment(time), 'seconds')) > 60) {
            result = moment.duration(now.diff(new moment(time), 'minutes')) + " minutes ago";
            if (result === "1 minutes ago") result = "1 minute ago";
        }
        else {
            result = moment.duration(now.diff(new moment(time), 'seconds')) + " seconds ago";
            if (result === "1 seconds ago") result = "1 second ago";
        }

        return result;
    }

    getComputerVisionLogData = () => {
        const bearerToken = localStorage.getItem("careXactSOS_BearerToken");

        if (bearerToken !== undefined) {
            const apiURL = localStorage.getItem("careXactSOS_ApiURL");
            const authHeader = "Bearer " + bearerToken;
            const subscriptionKey = localStorage.getItem("careXactSOS_SubscriptionKey");
            const headers = { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': subscriptionKey, 'Authorization': authHeader };

            fetch(apiURL + 'api/Device/ComputerVisionLog?productCode=' + this.props.productCode, { headers })
                .then(response => response.json())
                .then(data => { this.setState({ computerVisionLogData: data, isLoading: false }); })
                .catch(error => {
                    this.setState({ isLoading: false });
                });
        }
    }

    render() {
        const { classes } = this.props;

        if (this.state.isLoading) { return (loader); }

        var computerVisionLogData = this.state.computerVisionLogData;
        if (computerVisionLogData === null || computerVisionLogData === undefined || 
            computerVisionLogData.ComputerVisionLog === null || computerVisionLogData.ComputerVisionLog === undefined) {
            return (<div>
                        <GridContainer xs={12}>
                            <GridItem xs={12}>
                                <Card>
                                    <CardBody>
                                        <Button onClick={this.props.closeForm} color="blueSky">Close</Button>
                                        <Button onClick={this.getComputerVisionLogData()} color="blueSky">Refresh</Button>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>  
                    </div>);
        }
        
        return (
            <div>
                <GridContainer xs={12}>
                    <GridItem xs={12}>
                        <Card>
                            <CardBody>
                                <Button onClick={this.props.closeForm} color="blueSky">Close</Button>
                                <Button onClick={this.getComputerVisionLogData()} color="blueSky">Refresh</Button>
                            </CardBody>
                        </Card>
                    </GridItem>
                    {computerVisionLogData.ComputerVisionLog && computerVisionLogData.ComputerVisionLog.map(log => {
                            var date = new Date(log.DateTimeCreated + 'Z');
                            
                            return (
                                <GridItem xs={12} sm={4} xl={3}>
                                    <Card>
                                        <CardBody>
                                            {log.Model === "Google Vision" ? <img src="https://carexactsosprod.blob.core.windows.net/public/GoogleVision.png" width="120px" /> : <img src="https://carexactsosprod.blob.core.windows.net/public/AzureVision.png" width="150px" />} <br />
                                            <center>
                                                {this.getTime(log.DateTimeCreated)}<br/>
                                                <img src={log.ImageURL} style={{ minHeight: "200px", maxHeight: "200px" }} /><br />
                                                {log.Caption}<br />
                                                <a href={log.ImageURL} target="_blank" rel="noopener noreferrer">Full Screen</a>  |  <a href={"https://codebeautify.org/jsonviewer?input=" + log.RawResult} target="_blank" rel="noopener noreferrer">View JSON</a>
                                            </center>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                                );
                        })}
                    </GridContainer>  
            </div>
            );
    }
}

export default withStyles(style)(ComputerVisionLog);