import { grayColor } from "../../material-dashboard-pro-react.js";

import buttonStyle_NoTheme from "../components/buttonStyle_NoTheme.js";

const sweetAlertStyle = {
  cardTitle: {
    marginTop: "0",
    marginBottom: "3px",
    color: grayColor[2],
    fontSize: "18px"
  },
  center: {
    textAlign: "center"
  },
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  ...buttonStyle_NoTheme
};

export default sweetAlertStyle;
