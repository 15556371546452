import React from "react";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// core components
import GridContainer from "../../../../components/Grid/GridContainer.js";
import GridItem from "../../../../components/Grid/GridItem.js";

import customSelectStyle from "../../../../assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import extendedFormStyles from "../../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import alertStyles from "../../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2'
import startsWith from 'lodash.startswith';

import info from "../../../../assets/img/info.png";
import Fab from '@material-ui/core/Fab';
const infoButtonStyle = {
    margin: 0,
    top: '550',
    right: 60,
    bottom: 'auto',
    left: 'auto',
    position: 'fixed'
};

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
    ...customSelectStyle,
    ...extendedFormStyles,
    ...alertStyles
};

const info_image_icon = new Image().src = "https://carexactsosprod.blob.core.windows.net/help/emergencyContactIcon.png";
const info_image = new Image().src = "https://carexactsosprod.blob.core.windows.net/help/emergencyContacts.jpg";

class EmergencyContactsStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        PhoneNumber1: "",
        PhoneNumber1ReceiveCalls: false,
        PhoneNumber1ReceiveSMS: false,
        PhoneNumber2: "",
        PhoneNumber2ReceiveCalls: false,
        PhoneNumber2ReceiveSMS: false,
        PhoneNumber3: "",
        PhoneNumber3ReceiveCalls: false,
        PhoneNumber3ReceiveSMS: false,
        PhoneNumber4: "",
        PhoneNumber4ReceiveCalls: false,
        PhoneNumber4ReceiveSMS: false,
        alert: null,
        isPhoneNumber1Valid: true,
        isPhoneNumber2Valid: true,
        isPhoneNumber3Valid: true,
        isPhoneNumber4Valid: true,
    };
  }

    sendState() {
        return this.state;
    }

    hideAlert = () => {
        this.setState({alert: null});
    }

    showInfo(classes) {
        this.setState({
            alert:
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px", width: "500px" }}
                    title={""}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    confirmBtnCssClass={
                        classes.button + " " + classes.success
                    }>
                    <div style={{ textAlign: "justify" }}>
                        <center><img src={info_image_icon} height="107px" width="107px" objectfit="contain" /></center><br />
                        <img src={`${info_image}?${global.Date.now()}`} height="100%" width="100%" objectfit="contain" /><br/>
                        <ul>
                            <li>Enter the most important phone number first.  It will be called first.</li>
                            <li>When a fall is detected, or the SOS button is pressed, phone numbers will be called one at a time in order from 1 through 4.  This call sequence will repeat 3 times until the SOS emergency is cancelled by the pendant wearer.</li>
                            <li>Only phone numbers with <b><i>Receive Call On</i></b> will receive a call.  Phone numbers with <b><i>Receive Call Off</i></b> will be skipped.</li>
                            <li>Phone numbers with <b><i>Receive SMS On</i></b> will receive an immediate emergency SMS alert notification.</li>
                            <li>Additionally, phone numbers with <b><i>Receive SMS On</i></b> will receive battery low, device offline, no signal, and device configuration change SMS notifications.</li>
                        </ul>
                    </div>
                </SweetAlert>
        });
    }

    showAlert(classes, title, message) {
        this.setState({
            alert:
                <SweetAlert
                    error
                    style={{ display: "block", marginTop: "-100px", width: "400px" }}
                    title={title}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    confirmBtnCssClass={
                        classes.button + " " + classes.success
                    }>
                    <div>
                        { message }
                    </div>
                </SweetAlert>
        });
    }

    componentDidMount() {
        var device = JSON.parse(localStorage.getItem("careXactSOS_Device"));

        // if device not falsy
        if (device) {
            if (device.AuthorizedNumbers !== null) {
                if (device.AuthorizedNumbers !== undefined) {
                    var i = 0;
                    for (i = 0; i < device.AuthorizedNumbers.length; i++) {
                        var AuthorizedNumber = device.AuthorizedNumbers[i];

                        if (AuthorizedNumber.Serial === 0) {
                            this.setState({
                                PhoneNumber1: AuthorizedNumber.AuthorizedNumber,
                                PhoneNumber1ReceiveCalls: AuthorizedNumber.AcceptPhoneCall === "Y" ? true : false,
                                PhoneNumber1ReceiveSMS: AuthorizedNumber.AcceptSMS === "Y" ? true : false
                            });
                        }
                        if (AuthorizedNumber.Serial === 1) {
                            this.setState({
                                PhoneNumber2: AuthorizedNumber.AuthorizedNumber,
                                PhoneNumber2ReceiveCalls: AuthorizedNumber.AcceptPhoneCall === "Y" ? true : false,
                                PhoneNumber2ReceiveSMS: AuthorizedNumber.AcceptSMS === "Y" ? true : false
                            });
                        }
                        if (AuthorizedNumber.Serial === 2) {
                            this.setState({
                                PhoneNumber3: AuthorizedNumber.AuthorizedNumber,
                                PhoneNumber3ReceiveCalls: AuthorizedNumber.AcceptPhoneCall === "Y" ? true : false,
                                PhoneNumber3ReceiveSMS: AuthorizedNumber.AcceptSMS === "Y" ? true : false
                            });
                        }
                        if (AuthorizedNumber.Serial === 3) {
                            this.setState({
                                PhoneNumber4: AuthorizedNumber.AuthorizedNumber,
                                PhoneNumber4ReceiveCalls: AuthorizedNumber.AcceptPhoneCall === "Y" ? true : false,
                                PhoneNumber4ReceiveSMS: AuthorizedNumber.AcceptSMS === "Y" ? true : false
                            });
                        }
                    }
                }
            }
        }
    }

    isValidated() {
        var result = false;

        // a user must enter at least 1 number and that number must have either receive call or receive sms enabled
        if (this.state.PhoneNumber1.trim().length === 0 && this.state.PhoneNumber2.trim().length === 0 && this.state.PhoneNumber3.trim().length === 0 && this.state.PhoneNumber4.trim().length === 0) {
            result = false;
        }
        else {

            // check at least one valid emergency contact
            if (this.state.isPhoneNumber1Valid && (this.state.PhoneNumber1ReceiveCalls || this.state.PhoneNumber1ReceiveSMS))
                result = true;

            if (result !== true)
                if (this.state.isPhoneNumber2Valid && (this.state.PhoneNumber2ReceiveCalls || this.state.PhoneNumber2ReceiveSMS))
                    result = true;

            if (result !== true)
                if (this.state.isPhoneNumber3Valid && (this.state.PhoneNumber3ReceiveCalls || this.state.PhoneNumber3ReceiveSMS))
                    result = true

            if (result !== true)
                if (this.state.isPhoneNumber4Valid && (this.state.PhoneNumber4ReceiveCalls || this.state.PhoneNumber4ReceiveSMS))
                    result = true;
        }

        // don't let user enter invalid phone numbers.  Must select 000, 111, leave empty, or add a valid country code
        if (!this.state.isPhoneNumber1Valid || !this.state.isPhoneNumber2Valid || !this.state.isPhoneNumber3Valid || !this.state.isPhoneNumber4Valid)
            result = false

        if (result === false) {
            const { classes } = this.props;
            this.showAlert(classes, "Invalid Phone Number", "Valid phone numbers are 000, 111, or any number starting with a valid country code.  At least one valid phone number must have either Receive Call enabled or Receive SMS enabled.")
        }

        return result;
    }

    handlePhoneNumberOnChange = (index, value, countryData) => {

        // if truthy then append a + so when we POST to db phone numbers have a +
        if (value) {

            // strip the country code for 000 and 111 if selected
            if (value === '000' || value === '111' || value === ("+" + countryData.dialCode + '000') || value === ("+" + countryData.dialCode + '111')) {
                value = value.startsWith("+" + countryData.dialCode) ? value.replace("+" + countryData.dialCode, '') : value;
            }

            // add the + so slots in gem devices are prefixed with +.  Don't add to 000 or 111
            if (value !== '000' && value !== '111') {
                value = value.startsWith('+') ? value : "+" + value;
            }

            // format +610414999888 as +61414999888.  International format country dial code replaces the leading 0
            value = value.startsWith('+' + countryData.dialCode + '0') ? value.replace('+' + countryData.dialCode + '0', '+' + countryData.dialCode) : value;
        }

        const isPhoneValid = this.isPhoneNumberFormatValid(value, countryData);

        this.setState({
            [`PhoneNumber${index}`]: value,
            [`isPhoneNumber${index}Valid`]: isPhoneValid
        });
    };

    isPhoneNumberFormatValid = (inputNumber, country) => {
        if (inputNumber === '000' || inputNumber === '111' || inputNumber === '') {
            return true; // Allow 000 or 111 without a country code
        }

        if (country?.dialCode) {
            return startsWith(inputNumber, "+" + country.dialCode) || startsWith("+" + country.dialCode, inputNumber);
        }

        return false;
    };

    render() {
      const { classes } = this.props;
      
        return (
            <div>
                <Fab size="small" color="white" aria-label="add" style={infoButtonStyle}>
                    <img alt="" src={info} height="45" width="45" onClick={event => this.showInfo(classes)} />
                </Fab>
                <GridContainer justify="center">
                    {this.state.alert}
                    <GridItem xs={8} sm={8} md={8} lg={8}>
                        <h4 className={classes.infoText}>Set emergency contacts for your pendant</h4>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={12} />
                        <table>
                          <thead>
                            <tr>
                                <th style={{ fontSize: "12px", width: "60%" }}>Phone Number</th>
                                <th style={{ fontSize: "12px"}}>Receive Call</th>
                                <th style={{ fontSize: "12px" }}>Receive SMS</th>
                              </tr>
                          </thead>
                          <tbody>
                            <tr>
                                  <td>
                                    <PhoneInput
                                        placeholder="Enter phone number 1"
                                        autoFormat={false}
                                        onlyCountries={['au', 'nz']}
                                        value={this.state.PhoneNumber1}
                                        onChange={(value, countryData) => {
                                            // If the value is empty, set it directly without prepending the country code
                                            if (!value || value.length === 1) {
                                                this.handlePhoneNumberOnChange(1, value, countryData);
                                                return;
                                            }

                                            // weird edge case where if a user types a number then selects a country the component doesn't add the country code automatically
                                            if (countryData?.dialCode && !value.startsWith(countryData?.dialCode)) {
                                                value = `+${countryData.dialCode}${value}`;
                                            }

                                            this.handlePhoneNumberOnChange(1, value, countryData);
                                        }}
                                        countryCodeEditable={true}
                                        prefix={(this.state.PhoneNumber1 === '000' || this.state.PhoneNumber1 === '111') ? "" : "+"}
                                        isValid={(inputNumber, country, countries) => {
                                            // Allow if a valid country code is selected OR if the number is "000" or "111"
                                            if (inputNumber === '000' || inputNumber === '111') {
                                                return true; // Allow 000 or 111 without a country code
                                            }

                                            // If neither 000 nor 111, ensure a country code is selected
                                            // Ensure a country code is selected
                                            return countries.some((country) => {
                                                return (
                                                    inputNumber.startsWith(country.dialCode) ||
                                                    country.dialCode.startsWith(inputNumber)
                                                );
                                            });
                                        }}
                                        inputStyle={{ width: '100%' }}
                                    />
                                  </td>
                                <td style={{ paddingLeft: "25px" }}>
                                        <FormControlLabel control={<Switch checked={this.state.PhoneNumber1ReceiveCalls} onChange={e => this.setState({ PhoneNumber1ReceiveCalls: e.target.checked })} value="NoActivityAlertEnabled" classes={{ switchBase: classes.switchBase, checked: classes.switchChecked, thumb: classes.switchIcon, track: classes.switchBar }} />} classes={{ label: classes.label }} label={this.state.PhoneNumber1ReceiveCalls ? "On " : "Off"} />
                                  </td>
                                  <td>
                                        <FormControlLabel control={<Switch checked={this.state.PhoneNumber1ReceiveSMS} onChange={e => this.setState({ PhoneNumber1ReceiveSMS: e.target.checked })} value="NoActivityAlertEnabled" classes={{ switchBase: classes.switchBase, checked: classes.switchChecked, thumb: classes.switchIcon, track: classes.switchBar }} />} classes={{ label: classes.label }} label={this.state.PhoneNumber1ReceiveSMS ? "On " : "Off"} />
                                  </td>
                              </tr>
                              <tr>
                                    <td>
                                        <PhoneInput
                                            placeholder="Enter phone number 2"
                                            autoFormat={false}
                                            onlyCountries={['au', 'nz']}
                                            value={this.state.PhoneNumber2}
                                            onChange={(value, countryData) => {
                                                // If the value is empty, set it directly without prepending the country code
                                                if (!value || value.length === 1) {
                                                    this.handlePhoneNumberOnChange(2, value, countryData);
                                                    return;
                                                }

                                                // weird edge case where if a user types a number then selects a country the component doesn't add the country code automatically
                                                if (countryData?.dialCode && !value.startsWith(countryData?.dialCode)) {
                                                    value = `+${countryData.dialCode}${value}`;
                                                }

                                                this.handlePhoneNumberOnChange(2, value, countryData);
                                            }}
                                            countryCodeEditable={true}
                                            prefix={(this.state.PhoneNumber2 === '000' || this.state.PhoneNumber2 === '111') ? "" : "+"}
                                            isValid={(inputNumber, country, countries) => {
                                                // Allow if a valid country code is selected OR if the number is "000" or "111"
                                                if (inputNumber === '000' || inputNumber === '111') {
                                                    return true; // Allow 000 or 111 without a country code
                                                }

                                                // If neither 000 nor 111, ensure a country code is selected
                                                // Ensure a country code is selected
                                                return countries.some((country) => {
                                                    return (
                                                        inputNumber.startsWith(country.dialCode) ||
                                                        country.dialCode.startsWith(inputNumber)
                                                    );
                                                });
                                            }}
                                            inputStyle={{ width: '100%' }}
                                        />
                                    </td>
                                  <td style={{ paddingLeft: "25px" }}>
                                        <FormControlLabel control={<Switch checked={this.state.PhoneNumber2ReceiveCalls} onChange={e => this.setState({ PhoneNumber2ReceiveCalls: e.target.checked })} value="NoActivityAlertEnabled" classes={{ switchBase: classes.switchBase, checked: classes.switchChecked, thumb: classes.switchIcon, track: classes.switchBar }} />} classes={{ label: classes.label }} label={this.state.PhoneNumber2ReceiveCalls ? "On " : "Off"} />
                                  </td>
                                  <td>
                                        <FormControlLabel control={<Switch checked={this.state.PhoneNumber2ReceiveSMS} onChange={e => this.setState({ PhoneNumber2ReceiveSMS: e.target.checked })} value="NoActivityAlertEnabled" classes={{ switchBase: classes.switchBase, checked: classes.switchChecked, thumb: classes.switchIcon, track: classes.switchBar }} />} classes={{ label: classes.label }} label={this.state.PhoneNumber2ReceiveSMS ? "On " : "Off"} />
                                  </td>
                              </tr>
                                <tr>
                                    <td>
                                        <PhoneInput
                                            placeholder="Enter phone number 3"
                                            autoFormat={false}
                                            onlyCountries={['au', 'nz']}
                                            value={this.state.PhoneNumber3}
                                            onChange={(value, countryData) => {
                                                // If the value is empty, set it directly without prepending the country code
                                                if (!value || value.length === 1) {
                                                    this.handlePhoneNumberOnChange(3, value, countryData);
                                                    return;
                                                }

                                                // weird edge case where if a user types a number then selects a country the component doesn't add the country code automatically
                                                if (countryData?.dialCode && !value.startsWith(countryData?.dialCode)) {
                                                    value = `+${countryData.dialCode}${value}`;
                                                }

                                                this.handlePhoneNumberOnChange(3, value, countryData);
                                            }}
                                            countryCodeEditable={true}
                                            prefix={(this.state.PhoneNumber3 === '000' || this.state.PhoneNumber3 === '111') ? "" : "+"}
                                            isValid={(inputNumber, country, countries) => {
                                                // Allow if a valid country code is selected OR if the number is "000" or "111"
                                                if (inputNumber === '000' || inputNumber === '111') {
                                                    return true; // Allow 000 or 111 without a country code
                                                }

                                                // If neither 000 nor 111, ensure a country code is selected
                                                // Ensure a country code is selected
                                                return countries.some((country) => {
                                                    return (
                                                        inputNumber.startsWith(country.dialCode) ||
                                                        country.dialCode.startsWith(inputNumber)
                                                    );
                                                });
                                            }}
                                            inputStyle={{ width: '100%' }}
                                        />
                                    </td>
                                  <td style={{ paddingLeft: "25px" }}>
                                        <FormControlLabel control={<Switch checked={this.state.PhoneNumber3ReceiveCalls} onChange={e => this.setState({ PhoneNumber3ReceiveCalls: e.target.checked })} value="NoActivityAlertEnabled" classes={{ switchBase: classes.switchBase, checked: classes.switchChecked, thumb: classes.switchIcon, track: classes.switchBar }} />} classes={{ label: classes.label }} label={this.state.PhoneNumber3ReceiveCalls ? "On " : "Off"} />
                                  </td>
                                  <td>
                                        <FormControlLabel control={<Switch checked={this.state.PhoneNumber3ReceiveSMS} onChange={e => this.setState({ PhoneNumber3ReceiveSMS: e.target.checked })} classes={{ switchBase: classes.switchBase, checked: classes.switchChecked, thumb: classes.switchIcon, track: classes.switchBar }} />} classes={{ label: classes.label }} label={this.state.PhoneNumber3ReceiveSMS ? "On " : "Off"} />
                                  </td>
                              </tr>
                                <tr>
                                    <td>
                                        <PhoneInput
                                            placeholder="Enter phone number 4"
                                            autoFormat={false}
                                            onlyCountries={['au', 'nz']}
                                            value={this.state.PhoneNumber4}
                                            onChange={(value, countryData) => {
                                                // If the value is empty, set it directly without prepending the country code
                                                if (!value || value.length === 1) {
                                                    this.handlePhoneNumberOnChange(4, value, countryData);
                                                    return;
                                                }

                                                // weird edge case where if a user types a number then selects a country the component doesn't add the country code automatically
                                                if (countryData?.dialCode && !value.startsWith(countryData?.dialCode)) {
                                                    value = `+${countryData.dialCode}${value}`;
                                                }

                                                this.handlePhoneNumberOnChange(4, value, countryData);
                                            }}
                                            countryCodeEditable={true}
                                            prefix={(this.state.PhoneNumber4 === '000' || this.state.PhoneNumber4 === '111') ? "" : "+"}
                                            isValid={(inputNumber, country, countries) => {
                                                // Allow if a valid country code is selected OR if the number is "000" or "111"
                                                if (inputNumber === '000' || inputNumber === '111') {
                                                    return true; // Allow 000 or 111 without a country code
                                                }

                                                // If neither 000 nor 111, ensure a country code is selected
                                                // Ensure a country code is selected
                                                return countries.some((country) => {
                                                    return (
                                                        inputNumber.startsWith(country.dialCode) ||
                                                        country.dialCode.startsWith(inputNumber)
                                                    );
                                                });
                                            }}
                                            inputStyle={{ width: '100%' }}
                                        />
                                    </td>
                                  <td style={{ paddingLeft: "25px" }}>
                                        <FormControlLabel control={<Switch checked={this.state.PhoneNumber4ReceiveCalls} onChange={e => this.setState({ PhoneNumber4ReceiveCalls: e.target.checked })} value="NoActivityAlertEnabled" classes={{ switchBase: classes.switchBase, checked: classes.switchChecked, thumb: classes.switchIcon, track: classes.switchBar }} />} classes={{ label: classes.label }} label={this.state.PhoneNumber4ReceiveCalls ? "On " : "Off"} />
                                  </td>
                                  <td>
                                        <FormControlLabel control={<Switch checked={this.state.PhoneNumber4ReceiveSMS} onChange={e => this.setState({ PhoneNumber4ReceiveSMS: e.target.checked })} value="NoActivityAlertEnabled" classes={{ switchBase: classes.switchBase, checked: classes.switchChecked, thumb: classes.switchIcon, track: classes.switchBar }} />} classes={{ label: classes.label }} label={this.state.PhoneNumber4ReceiveSMS ? "On " : "Off"} />
                                  </td>
                              </tr>
                          </tbody>
                        </table>
                </GridContainer>
            </div>
    );
  }
}

EmergencyContactsStep.propTypes = {
    classes: PropTypes.object
};

export default withStyles(style, alertStyles)(EmergencyContactsStep);
