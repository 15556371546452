import React from "react";
import { Route, Redirect } from "react-router-dom";
const ProtectedRoute = ({ children, ...rest }) => {
    localStorage.setItem("careXactSOS_lastURL",window.location);

    return (
        <Route
            {...rest}
            render={({ location }) =>
                localStorage.getItem("careXactSOS_BearerToken") ? (children)
                    : (
                        <Redirect
                            to={{
                                pathname: "/auth/login-page",
                                state: { from: location }
                            }}
                        />
                    )
            }
        />
    );
};
export default ProtectedRoute;