import React from "react";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Slider from '@material-ui/core/Slider';

// core components
import GridContainer from "../../../../components/Grid/GridContainer.js";
import GridItem from "../../../../components/Grid/GridItem.js";

import customSelectStyle from "../../../../assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "../../../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import alertStyles from "../../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import info from "../../../../assets/img/info.png";
import Fab from '@material-ui/core/Fab';

const infoButtonStyle = {
    margin: 0,
    top: '550',
    right: 60,
    bottom: 'auto',
    left: 'auto',
    position: 'fixed'
};

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px"
  },
    ...customSelectStyle,
    ...customCheckboxRadioSwitch,
    ...alertStyles
};

const info_image_icon = new Image().src = "https://carexactsosprod.blob.core.windows.net/help/fallIcon.png";

class FallAlarmStep extends React.Component {
  constructor(props) {
        super(props);
        this.state = {
            fallEnabled: true,
            sensitivity: 5,
            alert: null
        };
    }

    componentDidMount() {
        var device = JSON.parse(localStorage.getItem("careXactSOS_Device"));

        if (device) {
            if (device !== undefined) {
                this.setState({
                    fallEnabled: device.FallDetection !== null ? device.FallDetection[0].Enabled === "Y" : false,
                    sensitivity: device.FallDetection !== null ? device.FallDetection[0].Sensitivity : 5
                });
            }
        }
    }

    sendState() {
        return this.state;
    }

    isValidated() {
        return true;
    }

    hideAlert = () => {
        this.setState({ alert: null });
    }

    showInfo(classes) {
        this.setState({
            alert:
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px", width: "500px" }}
                    title={""}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    confirmBtnCssClass={
                        classes.button + " " + classes.success
                    }>
                    <div style={{ textAlign: "justify" }}>
                        <center><img src={info_image_icon} height="107px" width="130px" objectfit="contain" /></center><br/>
                        <p>Fall detection allows an alert to be sent if the pendant detects a sharp impact.</p>
                        <p>Please note, detection of a fall relies on the pendant detecting a sharp impact or knock, in some instances falls may not be detected if the user lets themselves down gently or for example falls on a soft surface such as a bed or couch.</p>
                        <p>Also it is possible that the pendant may register a fall alert when a fall has not occurred because the pendant has been placed down sharply on a hard surface.</p>
                        <p>For these reasons it is advisable to discuss whether fall detection is an appropriate setting for the SOS pendant wearer.</p>
                    </div>
                </SweetAlert>
        });
    }

    valuetext = (value) => {
        if (value === 3) return "Low";
        if (value === 5) return "Moderate";
        if (value === 7) return "High";
    }

    handleSliderChange = (event, value) => {
        this.setState({ sensitivity: value });
    }

    render() {
        const { classes } = this.props;
        const marks = [{ value: 3, label: "Low" }, { value: 5, label: "Moderate" }, { value: 7, label: "High" }];

    return (
        <div>
            <Fab size="small" color="white" aria-label="add" style={infoButtonStyle}>
                <img src={info} height="45" width="45" onClick={event => this.showInfo(classes)} />
            </Fab>
            {this.state.alert}
            <GridContainer justify="center">
                <GridItem xs={8} sm={8}>
                    <h4 className={classes.infoText}>Is fall detection enabled for this pendant?</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={10}>
                    <GridContainer justify="center">
                    <table>
                        <tr>
                            <td>
                                Fall Detection
                            </td>
                            <td>
                                <FormControlLabel control={<Switch checked={this.state.fallEnabled} onChange={e => this.setState({ fallEnabled: e.target.checked })} value="fallDetectionEnabled" classes={{ switchBase: classes.switchBase, checked: classes.switchChecked, thumb: classes.switchIcon, track: classes.switchBar }} />} classes={{ label: classes.label }} label={this.state.fallEnabled ? "Enabled " : "Disabled"} />
                            </td>
                            </tr>
                            <tr>
                                <td>
                                    Sensitivity
                                </td>
                                <td>
                                    <Slider value={this.state.sensitivity}
                                            onChange={this.handleSliderChange}
                                            aria-labelledby="discrete-slider-custom"
                                            step={null}
                                            min={3}
                                            max={7}
                                            valueLabelFormat={this.valuetext}
                                            marks={marks}
                                        />
                                </td>
                            </tr>
                    </table>
                </GridContainer>
                </GridItem>
            </GridContainer>
        </div>
    );
    }
}

FallAlarmStep.propTypes = {
    classes: PropTypes.object
};

export default withStyles(style, alertStyles)(FallAlarmStep);
