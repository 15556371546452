import React from "react";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Slider from '@material-ui/core/Slider';

// core components
import GridContainer from "../../../../components/Grid/GridContainer.js";
import GridItem from "../../../../components/Grid/GridItem.js";

import customSelectStyle from "../../../../assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "../../../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import alertStyles from "../../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import info from "../../../../assets/img/info.png";
import Fab from '@material-ui/core/Fab';

const infoButtonStyle = {
    margin: 0,
    top: '550',
    right: 60,
    bottom: 'auto',
    left: 'auto',
    position: 'fixed'
};

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px"
  },
    ...customSelectStyle,
    ...customCheckboxRadioSwitch,
    ...alertStyles
};

const info_image_icon = new Image().src = "https://carexactsosprod.blob.core.windows.net/help/fallIcon.png";

class DeviceSettingsVisionStep extends React.Component {
  constructor(props) {
        super(props);
        this.state = {
            deviceId: null,
            privacyLevel: "medium",
            motionSensitivity: "medium",
            edgeDetectionThreshold: "200",
            objectPolygonsEnabled: true,
            alert: null
        };
    }

    componentDidMount() {
        var device = JSON.parse(localStorage.getItem("careXactSOS_Device"));

        if (device !== null) {
            if (device !== undefined) {
                var privacyLevel = "medium";
                var edgeDetectionThreshold = "200";
                var objectPolygonsEnabled = true;
                var motionSensitivity = "medium";

                var i;
                for (i = 0; i < device.DeviceSettings.length; i++) {
                    var deviceSetting = device.DeviceSettings[i];
                    if (deviceSetting.SettingKey.toLowerCase() === "privacylevel") privacyLevel = deviceSetting.SettingValue;
                    if (deviceSetting.SettingKey.toLowerCase() === "edgedetectionthreshold") edgeDetectionThreshold = deviceSetting.SettingValue;
                    if (deviceSetting.SettingKey.toLowerCase() === "objectpolygonsenabled") objectPolygonsEnabled = deviceSetting.SettingValue === "1" ? true : false;
                    if (deviceSetting.SettingKey.toLowerCase() === "motionsensitivity") motionSensitivity = deviceSetting.SettingValue;
                }

                this.setState({
                    deviceId: device.DeviceId,
                    privacyLevel: privacyLevel,
                    motionSensitivity: motionSensitivity,
                    edgeDetectionThreshold: edgeDetectionThreshold,
                    objectPolygonsEnabled: objectPolygonsEnabled
                });
            }
        }
    }

    sendState() {
        return this.state;
    }

    isValidated() {
        return true;
    }

    hideAlert = () => {
        this.setState({ alert: null });
    }

    showInfo(classes) {
        this.setState({
            alert:
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px", width: "500px" }}
                    title={""}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    confirmBtnCssClass={
                        classes.button + " " + classes.success
                    }>
                    <div style={{ textAlign: "justify" }}>
                        <center><img src={info_image_icon} height="107px" width="130px" objectfit="contain" /></center><br/>
                        <p>Fall detection allows an alert to be sent if the pendant detects a sharp impact.</p>
                        <p>Please note, detection of a fall relies on the pendant detecting a sharp impact or knock, in some instances falls may not be detected if the user lets themselves down gently or for example falls on a soft surface such as a bed or couch.</p>
                        <p>Also it is possible that the pendant may register a fall alert when a fall has not occurred because the pendant has been placed down sharply on a hard surface.</p>
                        <p>For these reasons it is advisable to discuss whether fall detection is an appropriate setting for the SOS pendant wearer.</p>
                    </div>
                </SweetAlert>
        });
    }

    valuetext = (value) => {
        if (value === 1) return "Low";
        if (value === 2) return "Medium";
        if (value === 3) return "High";
    }

    handleSliderChange = (event, value) => {
        var privacyLevel = "medium";

        if (value === 1) privacyLevel = "low";
        if (value === 2) privacyLevel = "medium";
        if (value === 3) privacyLevel = "high";

        this.setState({ privacyLevel: privacyLevel });
    }

    handleMotionSensitivitySliderChange = (event, value) => {
        var motionSensitivity = "medium";

        if (value === 1) motionSensitivity = "low";
        if (value === 2) motionSensitivity = "medium";
        if (value === 3) motionSensitivity = "high";

        this.setState({ motionSensitivity: motionSensitivity });
    }

    render() {
        const { classes } = this.props;
        const privacySliderMarks = [{ value: 1, label: "Low" }, { value: 2, label: "Medium" }, { value: 3, label: "High" }];
        var privacyLevel = this.state.privacyLevel === "low" ? 1 : this.state.privacyLevel === "medium" ? 2 : 3;

        const motionSensitivitySliderMarks = [{ value: 1, label: "Low" }, { value: 2, label: "Medium" }, { value: 3, label: "High" }];
        var motionSensitivity = this.state.motionSensitivity === "low" ? 1 : this.state.motionSensitivity === "high" ? 3 : 2;

    return (
        <div>
            <Fab size="small" color="white" aria-label="add" style={infoButtonStyle}>
                <img src={info} height="45" width="45" onClick={event => this.showInfo(classes)} />
            </Fab>
            {this.state.alert}
            <GridContainer justify="center">
                <GridItem xs={8} sm={8}>
                    <h4 className={classes.infoText}>Adjust the privacy level and settings for this device.</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={10}>
                    <GridContainer justify="center">
                        <table width="50%">
                            <th width="30%" /><th width="70%" />
                            <tr>
                                <td>Motion Sensitivity</td>
                                <td>
                                    <Slider value={motionSensitivity}
                                        onChange={this.handleMotionSensitivitySliderChange}
                                        aria-labelledby="discrete-slider-custom"
                                        step={null}
                                        min={1}
                                        max={3}
                                        valueLabelFormat={this.valuetext}
                                        marks={motionSensitivitySliderMarks}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Privacy Level</td>
                                <td>
                                    <Slider value={privacyLevel}
                                        onChange={this.handleSliderChange}
                                        aria-labelledby="discrete-slider-custom"
                                        step={null}
                                        min={1}
                                        max={3}
                                        valueLabelFormat={this.valuetext}
                                        marks={privacySliderMarks}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Object Polygons</td>
                                <td>
                                    <FormControlLabel control={
                                        <Switch
                                            checked={this.state.objectPolygonsEnabled}
                                            onChange={e => this.setState({ objectPolygonsEnabled: e.target.checked })}
                                            value="objectPolygonsEnabled"
                                            classes={{ switchBase: classes.switchBase, checked: classes.switchChecked, thumb: classes.switchIcon, track: classes.switchBar }} />} classes={{ label: classes.label }}
                                            label={this.state.objectPolygonsEnabled ? "Enabled " : "Disabled"} />
                                </td>
                            </tr>
                        </table>
                </GridContainer>
                </GridItem>
            </GridContainer>
        </div>
    );
    }
}

DeviceSettingsVisionStep.propTypes = {
    classes: PropTypes.object
};

export default withStyles(style, alertStyles)(DeviceSettingsVisionStep);
