import React from "react";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";
import { PayPalButton } from "react-paypal-button-v2";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "../../../../components/Grid/GridContainer.js";
import GridItem from "../../../../components/Grid/GridItem.js";

import alertStyles from "../../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
    },
    ...alertStyles
};

class PaymentStep extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            Loading : true,
            SubscriptionStatus: "",
            SubscriptionPlanId: "",
            alert: null
        };
    }

    componentDidMount() {
        const deviceId = localStorage.getItem("careXactSOS_DeviceId");
        const apiURL = localStorage.getItem("careXactSOS_ApiURL");
        const authHeader = "Bearer " + localStorage.getItem("careXactSOS_BearerToken");
        const subscriptionKey = localStorage.getItem("careXactSOS_SubscriptionKey");
        const headers = { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': subscriptionKey, 'Authorization': authHeader };

        fetch(apiURL + 'api/Subscription/SubscriptionPlanId?deviceId=' + deviceId, {
            method: 'GET',
            headers: headers
        })
            .then(response => response.json())
            .then(data => {
                this.setState({ SubscriptionPlanId: data });
                if (data.toLowerCase() === "free") {
                    this.updateSubscription();
                } else {
                    this.setState({ Loading: false });
                }
            })
            .catch(error => {
                alert("Error : Unable to update the device with subscription details.  Contact customer support for assistance.");
                console.log(error.message);
            });
    }

    updateSubscription = () => {
        const deviceId = localStorage.getItem("careXactSOS_DeviceId");
        const apiURL = localStorage.getItem("careXactSOS_ApiURL");
        const authHeader = "Bearer " + localStorage.getItem("careXactSOS_BearerToken");
        const subscriptionKey = localStorage.getItem("careXactSOS_SubscriptionKey");
        const headers = { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': subscriptionKey, 'Authorization': authHeader };

        fetch(apiURL + 'api/Subscription', {
            method: 'POST',
                body: JSON.stringify({
                    DeviceId: deviceId,
                    PaypalSubscriptionId: "",
                    PaypalOrderId: "",
                    Active: 1
                }),
                    headers: headers
                })
                .then(response => {
                    if (!response.ok)
                        throw Error(response.statusText);

                    if (response.ok)
                        this.setState({ SubscriptionStatus: "APPROVED", Loading: false });
                })
                    .catch(error => {
                        //alert("Error : Unable to update the device with subscription details.  Contact customer support for assistance and quote order Id : " + data.orderID);
                        console.log(error);
            });
    }

    sendState() {
        return this.state;
    }

    isValidated() {
        return true;
    }

    hideAlert = () => {
        this.setState({ alert: null });
    }

    showInfo() {
        const image_url = "https://carexactsosprod.blob.core.windows.net/help/payment.jpg";

        this.setState({
            alert:
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px", width: "800px" }}
                    title={""}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}>
                    <div>
                        <img src={`${image_url}?${global.Date.now()}`} height="100%" width="100%" objectFit="contain" />
                    </div>
                </SweetAlert>
        });
    }

    render() {
        const { classes } = this.props;        

        let currentComponent = this;

        if (this.state.Loading) return (<div><h4 className={classes.infoText}>Validating Subscription...</h4></div>);

        return (
            <div>
                {this.state.alert}
                <GridContainer justify="center">
                    <GridItem xs={12}>
                    {this.state.SubscriptionStatus === "APPROVED"
                        ? <h4 className={classes.infoText}>Your subscription has been activated.</h4>
                            : <h4 className={classes.infoText}>Payment Information</h4>
                    }
                    </GridItem>
                    <GridItem xs={12} sm={3}>
                    {this.state.SubscriptionStatus !== "APPROVED" ?
                            <PayPalButton
                                shippingPreference="NO_SHIPPING"
                                options={{ vault: true, clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID }}
                                onButtonReady={() => {
                                    this.setState({Loading: false});
                                }}
                                createSubscription={(data, actions) => {
                                        return actions.subscription.create({
                                            plan_id: this.state.SubscriptionPlanId
                                        });
                                        this.setState({ Loading: true });
                                    }}
                                onApprove={(data, actions) => {
                                        //if we hide the paypal button before the approve method is complete then the handler does not execute.
                                        //currentComponent.setState({ SubscriptionStatus: "APPROVED", Loading: false });

                                        currentComponent.setState({ SubscriptionStatus: "UPDATING", Loading: false });

                                        // Capture the funds from the transaction
                                        return actions.subscription.get().then(function (details) {
                                            const deviceId = localStorage.getItem("careXactSOS_DeviceId");
                                            const apiURL = localStorage.getItem("careXactSOS_ApiURL");
                                            const authHeader = "Bearer " + localStorage.getItem("careXactSOS_BearerToken");
                                            const subscriptionKey = localStorage.getItem("careXactSOS_SubscriptionKey");
                                            const headers = { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': subscriptionKey, 'Authorization': authHeader };

                                            fetch(apiURL + 'api/Subscription', {
                                                    method: 'POST',
                                                    body: JSON.stringify({
                                                        DeviceId: deviceId,
                                                        PaypalSubscriptionId: data.subscriptionID,
                                                        PaypalOrderId: data.orderID,
                                                        Active: 1
                                                    }),
                                                    headers: headers
                                                    })
                                                    .then(response => {
                                                        if (!response.ok)
                                                            throw Error(response.statusText);

                                                        if (response.ok)
                                                            currentComponent.setState({ SubscriptionStatus: "APPROVED", Loading: false });
                                                    })
                                                    .catch(error => {
                                                        //alert("Error : Unable to update the device with subscription details.  Contact customer support for assistance and quote order Id : " + data.orderID);
                                                        console.log(error);
                                                    });
                                        });
                                }}
                                onCancel={(details, data) => {
                                    this.setState({ SubscriptionStatus: "CANCELLED", Loading: false });
                                }}
                                onError={(details, data) => {
                                    this.setState({ SubscriptionStatus: "ERROR", Loading: false });
                                }}
                            />
                        :
                            <></>
                    }
                    {this.state.SubscriptionStatus === "UPDATING" &&
                        <h4 color="danger" className={classes.infoText}>Updating your device subscription.</h4>
                    }
                    {this.state.SubscriptionStatus === "APPROVED" &&
                        <h4 color="danger" className={classes.infoText}>Press Close or Finish to continue.</h4>
                    }
                    {this.state.SubscriptionStatus === "ERROR" &&
                        <h4 color="danger" className={classes.infoText}>Your pendant device will not be active until a subscription is purchased.</h4>
                    }
                    {this.state.SubscriptionStatus === "CANCELLED" &&
                        <h4 color="danger" className={classes.infoText}>Your pendant device will not be active until a subscription is purchased.</h4>
                    }
                    </GridItem>
                    <GridItem xs={12} justify="center">
                        {this.state.SubscriptionStatus !== "APPROVED" && <center><h7 className={classes.infoText}>Credit card details securely stored and processed by PayPal.</h7></center>}
                    </GridItem>
                </GridContainer>
            </div>
        );
  }
}

PaymentStep.propTypes = {
    classes: PropTypes.object
};

export default withStyles(style)(PaymentStep);
