import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

// core components
import Wizard from "../../../components/Wizard/Wizard.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";

import DeviceSettingsVisionStep from "./DeviceDetailsWizardSteps/DeviceSettingsVision.js";
import ContactDetailsStep from "./DeviceDetailsWizardSteps/ContactDetails.js";
import DeviceNameStep from "./DeviceDetailsWizardSteps/DeviceName.js";

import { makeStyles } from "@material-ui/core/styles";
import alertStyles from "../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useAlertStyles = makeStyles(alertStyles);

export default function WizardUpdateDeviceVision(props) {
    const alertClasses = useAlertStyles();

    const hideAlert = () => {
        setAlert(null);
    };

    const [Saving, setSaving] = React.useState(false);
    const [alert, setAlert] = React.useState(null);

    const showAlert = (title, message) => {
        setAlert(
            <SweetAlert
                error
                style={{ display: "block", marginTop: "-100px", width: "400px" }}
                title={title}
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}>
                {message}
            </SweetAlert>
        );
    };

    const handleFinish = async (event) => {
        if (Saving !== true) {
            setSaving(true);
            var existingDeviceRecord = JSON.parse(localStorage.getItem("careXactSOS_Device"));

            var deviceSettingsStep = event["deviceSettings"];
            var contactDetailsStep = event["contactDetails"];
            var deviceNameStep = event["deviceName"];

            var deviceSettingsChanged = false;
            var existingDeviceUpdated = false;
            var deviceNameChanged = false;
            var partyRecordChanged = false;
            var addressChanged = false;

            var privacyLevel = "medium";
            var edgeDetectionThreshold = "200";
            var objectPolygonsEnabled = true;
            var motionSensitivity = "";

            if (deviceSettingsStep !== undefined) {
                var i;
                for (i = 0; i < existingDeviceRecord.DeviceSettings.length; i++) {
                    var deviceSetting = existingDeviceRecord.DeviceSettings[i];
                    if (deviceSetting.SettingKey.toLowerCase() === "privacylevel") privacyLevel = deviceSetting.SettingValue;
                    if (deviceSetting.SettingKey.toLowerCase() === "edgedetectionthreshold") edgeDetectionThreshold = deviceSetting.SettingValue;
                    if (deviceSetting.SettingKey.toLowerCase() === "objectpolygonsenabled") objectPolygonsEnabled = deviceSetting.SettingValue === "1" ? true : false;
                    if (deviceSetting.SettingKey.toLowerCase() === "motionsensitivity") motionSensitivity = deviceSetting.SettingValue;
                }

                if (deviceSettingsStep.privacyLevel != privacyLevel ||
                    deviceSettingsStep.edgeDetectionThreshold !== edgeDetectionThreshold ||
                    deviceSettingsStep.objectPolygonsEnabled !== objectPolygonsEnabled ||
                    deviceSettingsStep.motionSensitivity !== motionSensitivity) {
                    deviceSettingsChanged = true;

                    privacyLevel = deviceSettingsStep.privacyLevel;
                    edgeDetectionThreshold = deviceSettingsStep.edgeDetectionThreshold;
                    objectPolygonsEnabled = deviceSettingsStep.objectPolygonsEnabled;
                    motionSensitivity = deviceSettingsStep.motionSensitivity;
                }
            }

            if (contactDetailsStep !== undefined) {
                // update an existing entry
                if (existingDeviceRecord.Party.FirstName !== contactDetailsStep.FirstName) partyRecordChanged = true;
                if (existingDeviceRecord.Party.LastName !== contactDetailsStep.LastName) partyRecordChanged = true;
                if (existingDeviceRecord.Party.PhoneNumber !== contactDetailsStep.PhoneNumber) partyRecordChanged = true;
                if (existingDeviceRecord.Party.StreetNumber !== contactDetailsStep.StreetNumber) partyRecordChanged = true;
                if (existingDeviceRecord.Party.StreetName !== contactDetailsStep.StreetName) partyRecordChanged = true;
                if (existingDeviceRecord.Party.StreetType !== contactDetailsStep.StreetType) partyRecordChanged = true;
                if (existingDeviceRecord.Party.Suburb !== contactDetailsStep.Suburb) partyRecordChanged = true;
                if (existingDeviceRecord.Party.PostCode !== contactDetailsStep.PostCode) partyRecordChanged = true;
                if (existingDeviceRecord.Party.State !== contactDetailsStep.State) partyRecordChanged = true;
                if (existingDeviceRecord.Party.Country !== contactDetailsStep.Country) partyRecordChanged = true;

                var existingAddress = existingDeviceRecord.Party.StreetNumber + existingDeviceRecord.Party.StreetName + existingDeviceRecord.Party.StreetType + existingDeviceRecord.Party.Suburb + existingDeviceRecord.Party.State + existingDeviceRecord.Party.Country;
                var contactDetailsStepAddress = contactDetailsStep.StreetNumber + contactDetailsStep.StreetName + contactDetailsStep.StreetType + contactDetailsStep.Suburb + contactDetailsStep.State + contactDetailsStep.Country;

                if (existingAddress != contactDetailsStepAddress) addressChanged = true;
            }

            if (deviceNameStep !== undefined) {
                if (existingDeviceRecord.DeviceName !== deviceNameStep.DeviceName) {
                    deviceNameChanged = true;
                    // do this so we know to trigger a party entity commit.  The API updates the party and device in the one stored proc
                    partyRecordChanged = true;
                }
            }

            if (deviceNameChanged) existingDeviceUpdated = true;
            if (partyRecordChanged) existingDeviceUpdated = true;
            if (deviceSettingsChanged) existingDeviceUpdated = true;

            if (existingDeviceUpdated === false) {
                props.closeFormNoRefresh();  // no changes at all so close without calling the API to refresh the UI
            }
            else {
                // Apply form data changes records in state
                const json = {
                    DeviceId: existingDeviceRecord.DeviceId,
                    DeviceName: deviceNameChanged ? deviceNameStep.DeviceName : existingDeviceRecord.DeviceName,
                    ProductCode: existingDeviceRecord.ProductCode,
                    Party: {
                        PartyId: existingDeviceRecord.Party.PartyId,
                        FirstName: partyRecordChanged ? contactDetailsStep.FirstName : existingDeviceRecord.Party.FirstName,
                        LastName: partyRecordChanged ? contactDetailsStep.LastName : existingDeviceRecord.Party.LastName,
                        PhoneNumber: partyRecordChanged ? contactDetailsStep.PhoneNumber : existingDeviceRecord.Party.PhoneNumber,
                        StreetNumber: addressChanged ? contactDetailsStep.StreetNumber : existingDeviceRecord.Party.StreetNumber,
                        StreetName: addressChanged ? contactDetailsStep.StreetName : existingDeviceRecord.Party.StreetName,
                        StreetType: addressChanged ? contactDetailsStep.StreetType : existingDeviceRecord.Party.StreetType,
                        Suburb: addressChanged ? contactDetailsStep.Suburb : existingDeviceRecord.Party.Suburb,
                        State: addressChanged ? contactDetailsStep.State : existingDeviceRecord.Party.State,
                        PostCode: addressChanged ? contactDetailsStep.PostCode : existingDeviceRecord.Party.PostCode,
                        Country: addressChanged ? contactDetailsStep.Country : existingDeviceRecord.Party.Country,
                        AddressLatitude: addressChanged ? contactDetailsStep.AddressLatitude : existingDeviceRecord.Party.AddressLatitude,
                        AddressLongitude: addressChanged ? contactDetailsStep.AddressLongitude : existingDeviceRecord.Party.AddressLongitude,
                        AddressUpdated: addressChanged,
                        EntityUpdated: partyRecordChanged || deviceSettingsChanged ? true : false
                    },
                    DeviceSettings: [
                        {
                            SettingKey: "PrivacyLevel",
                            SettingValue: privacyLevel
                        },
                        {
                            SettingKey: "EdgeDetectionThreshold",
                            SettingValue: edgeDetectionThreshold
                        },
                        {
                            SettingKey: "ObjectPolygonsEnabled",
                            SettingValue: objectPolygonsEnabled ? "1" : "0"
                        },
                        {
                            SettingKey: "MotionSensitivity",
                            SettingValue: motionSensitivity
                        }
                    ]
                };

                const apiURL = localStorage.getItem("careXactSOS_ApiURL");
                const authHeader = "Bearer " + localStorage.getItem("careXactSOS_BearerToken");
                const subscriptionKey = localStorage.getItem("careXactSOS_SubscriptionKey");
                const headers = { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': subscriptionKey, 'Authorization': authHeader };
                var responseOk = true;

                fetch(apiURL + 'api/Device/Vision', {
                    method: 'POST',
                    body: JSON.stringify(json),
                    headers: headers
                })
                    .then(function (response) {
                        responseOk = response.ok;
                        return response.json();
                    })
                    .then(response => {
                        if (!responseOk)
                            throw Error(response.Message);

                        props.closeForm("UPDATE");
                    })
                    .catch(error => {
                        setSaving(false);
                        showAlert("Error", "Unable to update the device. " + error);
                        console.log(error.message);
                    });
            }
        }
    };

    const handleClose = async (event) => {
        props.closeFormNoRefresh();
    }

    return (
        <GridContainer justify="center">
            <GridItem xs={12} sm={12}>
                <Wizard
                    color="blueSky"
                    validate
                    steps={[
                        { stepName: "Device Name", stepComponent: DeviceNameStep, stepId: "deviceName" },
                        { stepName: "Device Settings", stepComponent: DeviceSettingsVisionStep, stepId: "deviceSettings" },
                        { stepName: "Contact Details", stepComponent: ContactDetailsStep, stepId: "contactDetails" }
                    ]}
                    title=""
                    subtitle=""
                    //title="Update your device"
                    //subtitle="Configure the settings on your SOS pendant."
                    finishButtonClick={event => handleFinish(event)}
                    closeButtonClick={event => handleClose(event)}
                />
                {alert}
            </GridItem>
        </GridContainer>
    );
}
