import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

// core components
import Wizard from "../../../components/Wizard/Wizard.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";

import PaymentStep from "./DeviceDetailsWizardSteps/Payment.js";

import { makeStyles } from "@material-ui/core/styles";
import alertStyles from "../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useAlertStyles = makeStyles(alertStyles);

export default function WizardSubscription(props) {
    const alertClasses = useAlertStyles();

    const hideAlert = () => {
        setAlert(null);
    };

    const [alert, setAlert] = React.useState(null);

    const showAlert = (title, message) => {
        setAlert(
            <SweetAlert
                style={{ display: "block", marginTop: "-100px", width: "400px" }}
                title={title}
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}>
                {message}
            </SweetAlert>
        );
    };

    const handleFinish = async (event) => {
        if (event !== null) {
            var paymentStep = event["payment"];

            if (paymentStep.SubscriptionStatus !== "UPDATING") {
                if (paymentStep.SubscriptionPlanId === null) props.closeForm("ADD");
                if (paymentStep.SubscriptionPlanId === undefined) props.closeForm("ADD");
                if (paymentStep.SubscriptionStatus !== "APPROVED") props.closeForm("ADD");
                if (paymentStep.SubscriptionStatus === "APPROVED") props.closeForm("ADD_WITH_SUBSCRIPTION");
            }
        }
    };

    const handleClose = async (event) => {
        if (event !== null) {
            var paymentStep = event["payment"];

            if (paymentStep.SubscriptionStatus !== "UPDATING") {
                if (paymentStep.SubscriptionPlanId === null) props.closeForm("ADD");
                if (paymentStep.SubscriptionPlanId === undefined) props.closeForm("ADD");
                if (paymentStep.SubscriptionStatus !== "APPROVED") props.closeForm("ADD");
                if (paymentStep.SubscriptionStatus === "APPROVED") props.closeForm("ADD_WITH_SUBSCRIPTION");
            }
        }
    }

    return (
        <GridContainer justify="center">
            <GridItem xs={12} sm={12}>
                <Wizard
                    color="blueSky"
                    validate
                    steps={[
                        { stepName: "Subscription", stepComponent: PaymentStep, stepId: "payment" },
                    ]}
                    title=""
                    subtitle=""
                    //title="Add a new device"
                    //subtitle="Configure the settings of your new SOS pendant."
                    finishButtonClick={event => handleFinish(event)}
                    closeButtonClick={event => handleClose(event)}
                />
                {alert}
            </GridItem>
        </GridContainer>
    );
}
