/*eslint-disable*/
import React, { Component }  from "react";
import moment from 'moment';
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/components
import { withStyles } from '@material-ui/core/styles';

// core components
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";
import ReactTable from "../../../components/ReactTable/ReactTable.js";

// styles
import notificationsStyle from "../../../assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import alertStyles from "../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import Fab from '@material-ui/core/Fab';
import info from "../../../assets/img/info.png";

import Loader from 'react-loader-spinner';
const loaderStyle = { position: "fixed", top: "50%", left: "55%", transform: "translate(-50%, -50%)" }
const loader = (<div style={loaderStyle}> <Loader type="ThreeDots" color="#4cb5f5" width="200" height="200" /></div>);

const style = {
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center"
    },
    inputAdornmentIcon: {
        color: "#555"
    },
    choiche: {
        textAlign: "center",
        cursor: "pointer",
        marginTop: "20px"
    },
    ...notificationsStyle,
    ...alertStyles
};

const infoButtonStyle = {
    margin: 0,
    top: 200,
    right: 100,
    bottom: 'auto',
    left: 'auto',
    position: 'fixed',
    zIndex: 1000
};

class Alerts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            alertData: null,
            mobilePortaitMode: false,
            alert: null,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.checkOrientation);
        window.addEventListener('orientationchange', this.checkOrientation);
        this.checkOrientation();
        this.getAlertData();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkOrientation);
        window.removeEventListener('orientationchange', this.checkOrientation);
    }

    checkOrientation = () => {
        if (window.innerHeight > window.innerWidth) {
            this.setState({ mobilePortaitMode: true });
        } else {
            this.setState({ mobilePortaitMode: false });
        }

        this.forceUpdate();
    }

    getAlertData() {
        const bearerToken = localStorage.getItem("careXactSOS_BearerToken");

        if (bearerToken !== undefined) {
            const apiURL = localStorage.getItem("careXactSOS_ApiURL");
            const authHeader = "Bearer " + bearerToken;
            const subscriptionKey = localStorage.getItem("careXactSOS_SubscriptionKey");
            const headers = { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': subscriptionKey, 'Authorization': authHeader };

            fetch(apiURL + 'api/Device/AllAlerts', { headers })
                .then(response => response.json())
                .then(data => { this.setState({ alertData: data }); })
                .catch(error => {

                });
        }
    }   

    getTime(time) {
        var localDate = new Date();
        var utcDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);
        var now = moment(utcDate);
        var result = null;

        if (moment.duration(now.diff(new moment(time), 'years')) > 1) {
            result = moment.duration(now.diff(new moment(time), 'years')) + " years ago";
            if (result === "1 years ago") result = "1 year ago";
        }
        else if (moment.duration(now.diff(new moment(time), 'hours')) > 24) {
            result = moment.duration(now.diff(new moment(time), 'days')) + " days ago";
            if (result === "1 days ago") result = "1 day ago";
        }
        else if (moment.duration(now.diff(new moment(time), 'minutes')) > 60) {
            result = moment.duration(now.diff(new moment(time), 'hours')) + " hours ago"; ;
            if (result === "1 hours ago") result = "1 hour ago";
        }
        else if (moment.duration(now.diff(new moment(time), 'seconds')) > 60) {
            result = moment.duration(now.diff(new moment(time), 'minutes')) + " minutes ago";
            if (result === "1 minutes ago") result = "1 minute ago";
        }
        else {
            result = moment.duration(now.diff(new moment(time), 'seconds')) + " seconds ago";
            if (result === "1 seconds ago") result = "1 second ago";
        }

        return result;
    }

    getAlertType(alertCode) {
        if (alertCode === "SOS_Alarm") return "SOS Button Press";
        if (alertCode === "Fall_Down_Alarm") return "Fall Detected";
        if (alertCode === "Low_Power_Alarm") return "Battery Low";
        return alertCode;
    }

    hideAlert = () => {
        this.setState({ alert: null });
    }

    showInfo() {
        const { classes } = this.props;

        this.setState({
            alert:
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px", width: "320px" }}
                    title={""}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    confirmBtnCssClass={
                        classes.button + " " + classes.success
                    }>
                    <div style={{ textAlign: "center" }}>
                        Alert history is retained for one month.
                    </div>
                </SweetAlert>
        });
    }

    render() {
        const { classes } = this.props;

        let alerts = [];
        this.state.alertData != null ? alerts = this.state.alertData : [];

        if (alerts.length === 0) { return (loader); }

        let emptyObjArray = [];
        var emptyObj = {
            Name: null,
            Title: null,
            Phone: null,
            Time: null
        };
        emptyObjArray.push(emptyObj);

        return (
            alerts.Alerts !== null ?
                <GridContainer xs={12}>
                    {this.state.alert}
                    <GridItem xs={12}>
                        <Card>
                            <CardBody>
                                {this.state.mobilePortaitMode === true ?
                                    <ReactTable
                                        columns={[
                                            {
                                                Header: "Alert",
                                                accessor: "Title",
                                                disableFilters: false
                                            },
                                            {
                                                Header: "Name",
                                                accessor: "Name",
                                                disableFilters: false
                                            },
                                            {
                                                Header: "Phone",
                                                accessor: "Phone",
                                                disableFilters: false,
                                                minWidth: 100
                                            },
                                            {
                                                Header: "When",
                                                accessor: "Time",
                                                disableFilters: false,
                                                maxWidth: 100
                                            }
                                        ]}
                                        data={alerts.Alerts.map((prop, key) => {
                                            return {
                                                id: key,
                                                Name: prop["Name"],
                                                Title: prop["ImageURL"] !== null ? <a href={prop["ImageURL"]} target="_blank" rel="noopener noreferrer">{this.getAlertType(prop["AlertCode"])}</a> : this.getAlertType(prop["AlertCode"]),
                                                Phone: prop["PhoneNumber"],
                                                Time: this.getTime(prop["AlertDateTime"])
                                            };
                                        })}
                                    />
                                    :
                                    <ReactTable
                                        columns={[
                                            {
                                                Header: "Device Name",
                                                accessor: "DeviceName",
                                                disableFilters: false
                                            },
                                            {
                                                Header: "Alert",
                                                accessor: "Title",
                                                disableFilters: false
                                            },
                                            {
                                                Header: "Name",
                                                accessor: "Name",
                                                disableFilters: false
                                            },
                                            {
                                                Header: "Phone",
                                                accessor: "Phone",
                                                disableFilters: false,
                                                minWidth: 100
                                            },
                                            {
                                                Header: "When",
                                                accessor: "Time",
                                                disableFilters: false,
                                                maxWidth: 140
                                            }
                                        ]}
                                        data={alerts.Alerts.map((prop, key) => {
                                            return {
                                                id: key,
                                                DeviceName: prop["DeviceName"],
                                                Name: prop["Name"],
                                                Title: (prop["ImageURL"] !== null && prop["ImageURL"] !== "") ? <a href={prop["ImageURL"]} target="_blank" rel="noopener noreferrer">{this.getAlertType(prop["AlertCode"])}</a> : this.getAlertType(prop["AlertCode"]),
                                                Phone: prop["PhoneNumber"],
                                                Time: this.getTime(prop["AlertDateTime"])
                                            };
                                        })}
                                    />
                                }
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                :
                <GridContainer xs={12}>
                    <Fab size="small" color="white" aria-label="add" style={infoButtonStyle}>
                        <img src={info} height="45" width="45" onClick={event => this.showInfo(classes)} />
                    </Fab>
                    {this.state.alert}
                    <GridItem xs={12}>
                        <Card>
                            <CardBody>
                                {this.state.mobilePortaitMode === true ?
                                    <ReactTable
                                        columns={[
                                            {
                                                Header: "Title",
                                                accessor: "Title",
                                                disableFilters: true
                                            },
                                            {
                                                Header: "Name",
                                                accessor: "Name",
                                                disableFilters: true
                                            },
                                            {
                                                Header: "Phone",
                                                accessor: "Phone",
                                                disableFilters: true
                                            },
                                            {
                                                Header: "When",
                                                accessor: "Time",
                                                disableFilters: true
                                            }
                                        ]}
                                        data={emptyObjArray.map((prop, key) => {
                                            return {
                                                id: key,
                                                Name: prop["Name"],
                                                Title: prop["Name"],
                                                Phone: prop["Phone"],
                                                Time: prop["Time"]
                                            };
                                        })}
                                    />
                                    :
                                    <ReactTable
                                        columns={[
                                            {
                                                Header: "Device Name",
                                                accessor: "DeviceName",
                                                disableFilters: true
                                            },
                                            {
                                                Header: "Title",
                                                accessor: "Title",
                                                disableFilters: true
                                            },
                                            {
                                                Header: "Name",
                                                accessor: "Name",
                                                disableFilters: true
                                            },
                                            {
                                                Header: "Phone",
                                                accessor: "Phone",
                                                disableFilters: true
                                            },
                                            {
                                                Header: "When",
                                                accessor: "Time",
                                                disableFilters: true
                                            }
                                        ]}
                                        data={emptyObjArray.map((prop, key) => {
                                            return {
                                                id: key,
                                                DeviceName: prop["Name"],
                                                Name: prop["Name"],
                                                Title: prop["Name"],
                                                Phone: prop["Phone"],
                                                Time: prop["Time"]
                                            };
                                        })}
                                    />
                                    }
                                <br /><small style={{ color: 'red' }}>No alerts exist.</small>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
        );
    }
}

export default withStyles(style)(Alerts);